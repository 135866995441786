import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { FixedImage, FluidImage } from 'src/components/Image';
import S from './brown.HowItWorks.Styled';

export const HowItWorksBrown = ({ bgColor }) => {
  const data = useStaticQuery(graphql`
    query {
      line: file(relativePath: { eq: "showcase/brown/how-it-works-line.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      asset1: file(relativePath: { eq: "showcase/brown/how-it-works-1.png" }) {
        childImageSharp {
          fixed(width: 102) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      asset2: file(relativePath: { eq: "showcase/brown/how-it-works-2.png" }) {
        childImageSharp {
          fixed(width: 183) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      asset3: file(relativePath: { eq: "showcase/brown/how-it-works-3.png" }) {
        childImageSharp {
          fixed(width: 230) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  const steps = [
    {
      image: data.asset1,
      title: 'Connection',
      description: 'Educators & students set topics and ask questions asynchronously on the 100mentors app',
      ImageMargin: 'margin-left: 15px',
    },
    {
      image: data.asset2,
      title: 'Engagement',
      description:
        'Mentors & learners engage through the 100mentors’ bite-sized Q&A format to dig deeper into topics that matter',
      ImageMargin: 'margin-right: 25px',
    },
    {
      image: data.asset3,
      title: 'Integration',
      description:
        'Mentors, learners & educators meet in person to explore, try experiments and discuss topics that sparked their interest',
      ImageMargin: 'margin-top: 17px',
    },
  ];

  return (
    <S.HowItWorksSection bgColor={bgColor}>
      <S.PageWrapper>
        <S.Heading>How it works</S.Heading>
        <S.Description>
          Brown x 100mentors collaboration makes it easy for mentees and mentors to connect through a digital platform
          thus making our impact measurable.
        </S.Description>
        <S.LineContainer>
          <FluidImage image={data.line} alt="" />
        </S.LineContainer>
        <S.Steps>
          {steps.map(step => (
            <S.Step>
              <S.LogoContainer margin={step.ImageMargin}>
                <FixedImage image={step.image} alt="" />
              </S.LogoContainer>
              <S.SmallHeading>{step.title}</S.SmallHeading>
              <S.SmallDescription>{step.description}</S.SmallDescription>
            </S.Step>
          ))}
        </S.Steps>
      </S.PageWrapper>
    </S.HowItWorksSection>
  );
};

HowItWorksBrown.propTypes = {
  bgColor: PropTypes.string,
};

HowItWorksBrown.defaultProps = {
  bgColor: 'white',
};
