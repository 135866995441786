import styled from 'styled-components';
import { justifyContent, alignContent, alignItems, flexDirection, flexWrap } from 'styled-system';
import Wrapper from './Wrapper';

const FlexWrapper = styled(Wrapper)`
  display: flex;

  ${justifyContent};
  ${alignContent};
  ${alignItems};
  ${flexDirection};
  ${flexWrap};
`;

FlexWrapper.propTypes = {
  ...justifyContent.propTypes,
  ...alignContent.propTypes,
  ...alignItems.propTypes,
  ...flexDirection.propTypes,
  ...flexWrap.propTypes,
};

export default FlexWrapper;
