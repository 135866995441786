import styled from 'styled-components';
import Section from 'src/components/Section';
import { PageWrapper } from 'src/components/Wrapper';
import { media, colors, breakPoints } from 'src/styles/theme';

export default {
  LearnByStudyingSection: styled(Section)`
    background-color: #fafafa;
  `,
  PageWrapper: styled(PageWrapper)`
    max-width: 1280px;
    padding: 60px 20px 0 20px;

    ${media.sm`
      padding: 80px 20px 0 20px;
    `};
  `,
  HeadContainer: styled.div`
    position: relative;
    margin: 0px auto;
    max-width: 650px;

    ${media.md`
      max-width: 730px;
      margin: 0px 0px;
    `}
  `,
  BigText: styled.div`
    font-weight: 500;
    font-size: 16px;
    color: rgba(62, 62, 62, 0.8);
    margin-bottom: 5px;

    ${media.sm`
      font-size: 29px;
      margin-bottom: 10px;
    `};
  `,
  Text: styled.div`
    color: ${colors.hmGrey3};
    font-size: 14px;
    max-width: 750px;
    margin-bottom: 30px;

    ${media.sm`
      font-size: 18px;
      margin-bottom: 60px;
    `};
  `,
  Arrow: styled.img`
    display: none;
    position: absolute;
    top: 30px;
    left: 615px;

    ${media.md`
      display: block;
    `};
  `,
  CardsContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;

    ${media.md`
      margin-bottom: 20px;
    `};
  `,
  Link: styled.a`
    text-decoration: none;
    color: ${colors.hmBlack};
    margin-bottom: 30px;

    ${media.sm`
      margin-bottom: 40px;
    `};
  `,
  Card: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 20px 25px 25px 25px;
    border-radius: 14px;
    max-width: 330px;
    width: 100%;
    box-sizing: border-box;
    cursor: ${({ disabled }) => !disabled && 'pointer'};

    ${media.sm`
      height: 550px;
    `};

    @media only screen and (min-width: ${breakPoints.md.min}) {
      padding: 25px;
      margin: 0 15px;
      max-width: 360px;
      height: 670px;
      transition: all 150ms ease;

      :hover {
        transform: ${({ disabled }) => !disabled && 'scale(1.1)'};
        filter: ${({ disabled }) => !disabled && 'drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15))'};
      }
    }
  `,
  Icon: styled.img`
    height: 58px;
    margin-bottom: 10px;
  `,
  Title: styled.div`
    font-weight: 500;
  `,
  Description: styled.div`
    font-size: 16px;
    margin-bottom: 15px;
  `,
  Extra: styled.div`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
  `,
  StepContainer: styled.div`
    display: flex;
    align-items: flex-start;

    :not(:last-child) {
      margin-bottom: 20px;
    }
  `,
  TickIcon: styled.img`
    margin-right: 10px;
  `,
  Step: styled.div`
    font-size: 16px;
    max-width: 280px;

    & > span {
      color: ${colors.hmGrey3};
    }
  `,
  LearnMore: styled.div`
    color: ${colors.hmBrandPurple};
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;

    & > img {
      margin-left: 5px;
    }

    ${media.sm`
      margin-top: auto;
    `};
  `,
  SavingsWrapper: styled.div`
    padding: 0 10px;

    ${media.sm`
      padding: 0 40px;
    `};

    ${media.md`
      padding: 0 110px;
    `};
  `,
  SavingsContainer: styled.div`
    position: relative;
    max-width: 760px;
    width: 100%;
    text-align: center;
    border-radius: 15px 15px 0 0;
    padding: 15px 30px 25px 10px;
    margin: 0 auto;
    box-sizing: border-box;
    background: rgba(241, 236, 255, 0.44);

    ${media.sm`
      max-width: 640px;
    `};

    ${media.md`
      max-width: 760px;
    `};
  `,
  SavingsTitle: styled.div`
    font-size: 15px;
    line-height: 18px;
    max-width: 380px;
    margin: 0 auto 5px auto;

    ${media.sm`
      font-size: 29px;
      line-height: 41px;
      margin-bottom: 10px;
      max-width: 560px;
    `};
  `,
  Disclaimer: styled.div`
    font-style: italic;
    font-size: 11px;
    line-height: 14px;
    max-width: 380px;
    margin: 0 auto;

    ${media.sm`
      font-size: 13px;
      line-height: 21px;
      max-width: 465px;
    `};
  `,
  WalletIcon: styled.img`
    position: absolute;
    top: 30px;
    right: -30px;
    width: 55px;

    ${media.sm`
      top: 60px;
      right: -65px;
      width: 127px;
    `};
  `,
  LaptopContainer: styled.div`
    position: relative;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  `,
  LaptopImage: styled.img`
    width: 120%;
    margin-top: -20px;
    margin-bottom: -50px;

    ${media.sm`
      margin-top: -30px;
      margin-bottom: -110px;
    `};
    ${media.md`
      width: 100%;
      margin-top: -35px;
      margin-bottom: -135px;
    `};
  `,
};
