const font = {
  fontSizes: {
    s: '12px',
    m: '14px',
    l: '16px',
    xl: '18px',
    xxl: '26px',
    xxxl: '32px',
  },
  headingFontSizes: {
    xs: '12px',
    s: '17px',
    m: '32px',
  },
  fonts: {
    roboto: `'Roboto', sans-serif`,
    lato: `'Lato', sans-serif`,
    gloriaHallelujah: `'Gloria Hallelujah', cursive`,
    robotoMono: `'Roboto Mono', 'Lato', sans-serif`,
  },
  fontWeights: {
    light: '300',
    normal: '400',
    bold: '600',
  },
};

export default font;
