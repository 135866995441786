import styled from 'styled-components';
import Section from 'src/components/Section';
import Wrapper from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import { colors, media, font } from 'src/styles/theme';

export default {
  HeadSection: styled(Section)`
    background-image: linear-gradient(135deg, #ac2fde 0%, #5235c9 100%);
  `,
  PageWrapper: styled(Wrapper)`
    box-sizing: border-box;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 20px 80px 20px;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  MentorsLogo: styled.img`
    align-self: flex-start;
    margin: 10px;
    width: 165px;
  `,
  LogoContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin: 15px 0 20px 0;
  `,
  LogoTitle: styled(Heading)`
    color: ${colors.white};
    font-weight: ${font.fontWeights.bold};
    font-size: ${font.headingFontSizes.xs};
    line-height: 14px;
    margin: 0;
  `,
  ComapnyLogo: styled.img`
    margin-top: 8px;
  `,
  Heading: styled(Heading)`
    font-size: 38px;
    text-align: center;
    color: #ffffff;
    max-width: 400px;
    margin-bottom: 25px;

    ${media.sm`
      max-width: 700px;
      margin-bottom: 20px;
    `};
  `,
  Line: styled.div`
    width: 100%;
    max-width: 615px;
    height: 1px;
    background-color: #ffffff;
    width: 90%;
    margin-bottom: 10px;

    ${media.sm`
      margin-bottom: 40px;
    `};
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 1180px;
    width: 100%;

    ${media.md`
      flex-direction: row-reverse;
    `};
  `,
  HeroImageContainer: styled.div`
    width: 300px;
    margin: 25px 0;

    ${media.sm`
      width: 355px;
    `};
  `,
  TextContainer: styled.div`
    text-align: left;
    margin-right: 50px;
  `,
  Title: styled(Heading)`
    color: #ffffff;
    margin-bottom: 10px;
    max-width: 700px;
    ${media.sm`
      margin-bottom: 20px;
    `};
  `,
  Description: styled.div`
    font-weight: ${font.fontWeights.light};
    color: #ffffff;
    max-width: 635px;
  `,
};
