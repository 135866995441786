import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import InnerLink from 'src/components/Link/InnerLink';
import Button from 'src/components/Button';
import { useLocalStorage } from 'src/utils';
import media from 'src/styles/theme/media';
import { colors } from '../../styles/theme';

const Wrapper = styled.div`
  z-index: 999;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};
  padding-right: 20px;

  ${media.sm`
    padding-right: 40px;
  `}
`;

const Text = styled.div`
  flex: 1;
  padding: 10px 20px;
  color: ${colors.hmBlack};
  font-size: 14px;
  text-align: left;

  ${media.sm`
    padding: 20px 40px;
    font-size: 16px;
    text-align: center;
  `}
`;

const CloseButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const CookieLink = styled(InnerLink)`
  text-decoration: underline;
`;

const GDRPBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [hideGDPRBanner, setHideGDPRBanner] = useLocalStorage('hide-gdpr-banner', false);

  useEffect(() => {
    if (hideGDPRBanner === false) setShowBanner(true);
    return () => null;
  }, [hideGDPRBanner]);

  if (hideGDPRBanner) return null;
  if (!showBanner) return null;

  return (
    <Wrapper>
      <Text>
        We use cookies to give you the best possible experience on our website. By continuing to browse this site, you
        give consent for cookies to be used. <CookieLink to="/cookies">Cookie Policy</CookieLink>
      </Text>
      <CloseButton color="hmBrandPurple" onClick={() => setHideGDPRBanner(true)}>
        I agree
      </CloseButton>
    </Wrapper>
  );
};

export default GDRPBanner;
