import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Paragraph from '../../components/Paragraph';
import Wrapper, { FlexWrapper } from '../../components/Wrapper';
import { Image } from '../../components/Image';
import theme from '../../styles/theme';

const HeadingSmall = styled.h2`
  font-size: ${theme.fontSizes.xl};
  font-weight: 900;
  margin: 0;
  margin-left: 15px;
`;

const LifeAtMentorsGridItem = ({ title, iconSrc, children, gridArea }) => (
  <Wrapper p={['5px', '20px']} gridArea={gridArea}>
    <FlexWrapper flexDirection="column">
      <FlexWrapper alignItems="flex-end" mb="10px">
        <Image src={iconSrc} height="22px" />
        <HeadingSmall>{title}</HeadingSmall>
      </FlexWrapper>
      <Paragraph lineHeight="1.39" fontWeight={theme.fontWeights.light}>
        {children}
      </Paragraph>
    </FlexWrapper>
  </Wrapper>
);

LifeAtMentorsGridItem.propTypes = {
  title: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  gridArea: PropTypes.string.isRequired,
};

export default LifeAtMentorsGridItem;
