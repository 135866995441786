import { css } from 'styled-components';
import breakpoints from './breakpoints';

function resCss(input, breakpoint) {
  return css`
    @media only screen and (min-width: ${breakpoint}) {
      ${input};
    }
  `;
}

function xs(input) {
  return resCss(input, breakpoints.xs.min);
}

function sm(input) {
  return resCss(input, breakpoints.sm.min);
}

function md(input) {
  return resCss(input, breakpoints.md.min);
}

function lg(input) {
  return resCss(input, breakpoints.lg.min);
}

function xlg(input) {
  return resCss(input, breakpoints.xlg.min);
}

export default {
  xs,
  sm,
  md,
  lg,
  xlg,
};
