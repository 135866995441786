import styled from 'styled-components';
import { media, colors, font } from 'src/styles/theme';

export default {
  MentorsContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto 15px auto;
  `,
  Mentor: styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${colors.hmBlue};
    text-decoration: none;
    width: 150px;
    margin: 0 15px 30px 15px;
    ${media.sm`
      margin: 0 50px 35px 50px;
    `};
  `,
  MentorName: styled.div`
    margin-top: 15px;
    font-size: ${font.fontSizes.l};
    text-transform: uppercase;
  `,
  SeeMore: styled.div`
    width: 120px;
    color: ${colors.hmBlue};
    text-transform: uppercase;
  `,
};
