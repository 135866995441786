import React from 'react';
import PropTypes from 'prop-types';
import answersStatIcon from 'src/assets/images/showcase/answers-icon.svg';
import studentsStatIcon from 'src/assets/images/showcase/inspired-icon.svg';
import hoursContentIcon from 'src/assets/images/showcase/hoursContent-icon.svg';
import hoursContentLearnersIcon from 'src/assets/images/showcase/hoursContentLearners-icon.svg';
import { colors } from 'src/styles/theme';
import S from './template.ImpactReport.Styled';

export const ImpactReportTemplate = ({
  bgColor,
  heading,
  title,
  answersNumber,
  studentsNumber,
  minutesOfContentCreated,
  minutesOfContentConsumed,
  statCards,
  maxWidth,
  justifyContent,
}) => (
  <S.ImpactReportSection bgColor={bgColor}>
    <S.PageWrapper>
      <S.Content>
        {heading && <S.SmallHeading>{heading}</S.SmallHeading>}
        {title && <S.Title>{title}</S.Title>}
        <S.StatsContainer maxWidth={maxWidth} justifyContent={justifyContent}>
          {answersNumber && (
            <S.StatCard
              bgImageColor={colors.hmBrandPurple10}
              image={answersStatIcon}
              stat={answersNumber}
              statColor={colors.hmBrandPurple}
              text="answers recorded"
            />
          )}
          {studentsNumber && (
            <S.StatCard
              bgImageColor={colors.hmBrandYellow10}
              image={studentsStatIcon}
              stat={studentsNumber}
              statColor={colors.hmBrandYellow}
              text="students inspired"
            />
          )}
          {minutesOfContentCreated && (
            <S.StatCard
              bgImageColor={colors.hmGreen10}
              image={hoursContentIcon}
              stat={minutesOfContentCreated}
              statColor={colors.hmGreen}
              text="minutes of content created"
            />
          )}
          {minutesOfContentConsumed && (
            <S.StatCard
              bgImageColor={colors.hmBlue10}
              image={hoursContentLearnersIcon}
              stat={minutesOfContentConsumed}
              statColor={colors.hmBlue}
              text="minutes of content consumed by learners"
            />
          )}
          {statCards.map(statCard => (
            <S.StatCard
              key={statCard.text}
              bgImageColor={colors[`${statCard.color}10`]}
              image={statCard.image}
              stat={statCard.number}
              statColor={colors[statCard.color]}
              text={statCard.text}
              maxWidth={statCard.maxWidth}
            />
          ))}
        </S.StatsContainer>
      </S.Content>
    </S.PageWrapper>
  </S.ImpactReportSection>
);

ImpactReportTemplate.propTypes = {
  bgColor: PropTypes.string,
  heading: PropTypes.string,
  title: PropTypes.string.isRequired,
  answersNumber: PropTypes.number,
  studentsNumber: PropTypes.number,
  minutesOfContentCreated: PropTypes.number,
  minutesOfContentConsumed: PropTypes.number,
  statCards: PropTypes.array,
  maxWidth: PropTypes.string,
  justifyContent: PropTypes.string,
};

ImpactReportTemplate.defaultProps = {
  bgColor: 'white',
  heading: undefined,
  answersNumber: undefined,
  studentsNumber: undefined,
  minutesOfContentCreated: undefined,
  minutesOfContentConsumed: undefined,
  statCards: [],
  maxWidth: undefined,
  justifyContent: undefined,
};
