import styled from 'styled-components';
import Section from 'src/components/Section';
import { PageWrapper } from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import { font, media } from 'src/styles/theme';

export default {
  Section: styled(Section)``,
  PageWrapper: styled(PageWrapper)`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
  `,
  Heading: styled(Heading)``,
  SubHeading: styled(Heading)`
    margin-top: 5px;
    font-size: ${font.fontSizes.xl};
    font-weight: ${font.fontWeights.light};
    text-align: center;
  `,
  Content: styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-top: 50px;

    ${media.md`
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: center;
      gap: 15px;
    `}
  `,
  ImageWrapper: styled.div`
    flex: 1;
    width: 100%;
    max-width: 510px;
    min-width: 320px;
  `,
};
