import styled from 'styled-components';
import { PageWrapper } from 'src/components/Wrapper';
import { TinyHeading } from 'src/components/Heading';
import media from 'src/styles/theme/media';

export default {
  CompaniesSection: styled.section`
    background-color: ${({ backgroundColor }) => backgroundColor};
    box-sizing: border-box;
  `,
  PageWrapper: styled(PageWrapper)`
    padding: 25px 0;
  `,
  Heading: styled(TinyHeading)`
    margin-bottom: 20px;
    text-align: center;
  `,
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
  `,
  Logo: styled.div`
    width: ${({ width }) => width};
    padding: 0 15px;
    opacity: ${({ opacity }) => opacity};

    ${media.sm`
      padding: 0 25px;
    `};
  `,
};
