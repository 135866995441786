import React from 'react';
import PropTypes from 'prop-types';
import Carousel3D from 'src/components/Carousel/Carousel3D';
import S from './template.PopularQuestions.Styled';

const QuestionCard = ({ question, extra, gradient }) => (
  <S.QuestionCard>
    <S.QuestionContainer gradient={gradient} bottomRadius={!extra}>
      <S.Question>{question}</S.Question>
    </S.QuestionContainer>
    {extra && (
      <S.QuestionCardTextConatiner>
        <div>{extra}</div>
      </S.QuestionCardTextConatiner>
    )}
  </S.QuestionCard>
);

QuestionCard.propTypes = {
  question: PropTypes.string.isRequired,
  extra: PropTypes.node,
  gradient: PropTypes.string,
};

QuestionCard.defaultProps = {
  extra: null,
  gradient: 'GREEN',
};

export const PopularQuestionsTemplate = ({ title, questions, bgColor }) => {
  const slides = questions.map(question => {
    return {
      key: question.question,
      content: <QuestionCard question={question.question} extra={question.extra} gradient={question.gradient} />,
    };
  });

  return (
    <S.PopularQuestionsSection bgColor={bgColor}>
      <S.PageWrapper>
        <S.SmallHeading>APP HIGHLIGHTS</S.SmallHeading>
        <S.Heading>{title}</S.Heading>
        <Carousel3D slides={slides} />
      </S.PageWrapper>
    </S.PopularQuestionsSection>
  );
};

PopularQuestionsTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({ question: PropTypes.string.isRequired, extra: PropTypes.node, gradient: PropTypes.string }),
  ).isRequired,
  bgColor: PropTypes.string,
};

PopularQuestionsTemplate.defaultProps = {
  bgColor: 'white',
};
