import styled from 'styled-components';
import InnerLink from 'src/components/Link/InnerLink';
import theme from 'src/styles/theme';

export default {
  CookiesContent: styled.div`
    font-size: ${theme.fontSizes.m};
    line-height: 1.42;

    li {
      margin: 8px 0px;
    }

    a,
    a:visited {
      color: ${theme.colors.hmBrandPurple};
      word-break: break-word;
      &:hover {
        opacity: 0.8;
      }
      transition: all 0.25 ease;
    }
  `,
  InnerLink: styled(InnerLink)`
    text-decoration: underline;
  `,
  Table: styled.table`
    border-spacing: 0px;
    border-collapse: collapse;
    margin: 24px 0px;
    thead {
      font-weight: bold;
    }
    td {
      border: 1px solid ${theme.colors.hmGrey2};
      padding: 6px;
    }
  `,
  HeadCell: styled.td`
    min-width: 150px;
    background-color: ${theme.colors.hmGrey1};
  `,
};
