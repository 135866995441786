import React from 'react';
import PropTypes from 'prop-types';
import { InnerLink } from 'src/components/Link';
import S from './template.Footer.Styled';

const YEAR = new Date().getFullYear();

export const FooterTemplate = ({ bgColor, children }) => (
  <S.FooterSection bgColor={bgColor}>
    <S.LogoWrapper>
      <S.Logo type="square" />
    </S.LogoWrapper>
    <S.Wrapper>
      {children}
      <S.HorizontalLine />
      <S.CopyrightContainer>
        <S.Copyright>100mentors © {YEAR} All Rights Reserved</S.Copyright>
        <S.TermsContainer>
          <InnerLink to="/terms" color="hmGrey3">
            Terms and Conditions
          </InnerLink>
          <InnerLink to="/privacy" color="hmGrey3">
            Privacy Policy
          </InnerLink>
          <InnerLink to="/cookies" color="hmGrey3">
            Cookie Policy
          </InnerLink>
        </S.TermsContainer>
      </S.CopyrightContainer>
    </S.Wrapper>
  </S.FooterSection>
);

FooterTemplate.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node,
};

FooterTemplate.defaultProps = {
  bgColor: 'white',
  children: null,
};
