import React from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'src/components/FontIcon';

import { FixedImage } from 'src/components/Image';
import { graphql, useStaticQuery } from 'gatsby';
import { MenuItem } from '../Menu';
import { InnerLink } from '../Link';
import Styled from './Footer.Styled';

const YEAR = new Date().getFullYear();

const Footer = ({ bg, contact, hasRecaptcha }) => {
  const { espaImage } = useStaticQuery(graphql`
    query {
      espaImage: file(relativePath: { eq: "e-bannersEUESF180X90.jpg" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Styled.Section bg={bg}>
      <Styled.LogoWrapper>
        <Styled.Logo type="square" />
      </Styled.LogoWrapper>
      <Styled.PageWrapper>
        <Styled.Grid>
          <Styled.GridCompanyArea>
            <Styled.AreaTitle>Company</Styled.AreaTitle>
            <Styled.Menu orientation="vertical">
              <MenuItem>
                <Styled.InnerLink to="/about" color="hmGrey3">
                  About Us
                </Styled.InnerLink>
              </MenuItem>
              <MenuItem>
                <Styled.InnerLink to="/jobs" color="hmGrey3">
                  Jobs
                </Styled.InnerLink>
              </MenuItem>
              <MenuItem>
                <Styled.Link color="hmGrey3" href="https://blog.100mentors.com/">
                  Blog
                </Styled.Link>
              </MenuItem>
            </Styled.Menu>
          </Styled.GridCompanyArea>
          <Styled.GridContactArea>
            <Styled.AreaTitle>Contact</Styled.AreaTitle>
            <Styled.ContactContent>{contact}</Styled.ContactContent>
            <Styled.SocialItems />
          </Styled.GridContactArea>
          <Styled.GridDownloadAppArea>
            <Styled.AreaTitle>Download Our App</Styled.AreaTitle>
            <Styled.AppStoreItem>
              <Styled.AppStoreLink
                href="https://apps.apple.com/gr/app/100mentors-ai/id6473743638"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontIcon name="app-store" fontSize={45} />
              </Styled.AppStoreLink>
            </Styled.AppStoreItem>
            <Styled.AppStoreItem>
              <Styled.AppStoreLink
                href="https://play.google.com/store/apps/details?id=com.hundredmentors.ai&hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontIcon name="google-play" fontSize={45} />
              </Styled.AppStoreLink>
            </Styled.AppStoreItem>
          </Styled.GridDownloadAppArea>
        </Styled.Grid>
        {hasRecaptcha && (
          <Styled.ReCaptchaInfo>
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>{' '}
            apply.
          </Styled.ReCaptchaInfo>
        )}
        <Styled.HorizontalLine hasRecaptcha={hasRecaptcha} />
        <Styled.CopyrightContainer>
          <Styled.Copyright>100mentors © {YEAR} All Rights Reserved</Styled.Copyright>
          <Styled.TermsContainer>
            <InnerLink to="/terms" color="hmGrey3">
              Terms and Conditions
            </InnerLink>
            <InnerLink to="/privacy" color="hmGrey3">
              Privacy Policy
            </InnerLink>
            <InnerLink to="/cookies" color="hmGrey3">
              Cookie Policy
            </InnerLink>
          </Styled.TermsContainer>
          <Styled.SponsorContainer>
            <a href="downloads/elevate_EN_GR.pdf" download>
              <FixedImage image={espaImage} alt="ESPA" />
            </a>
          </Styled.SponsorContainer>
        </Styled.CopyrightContainer>
      </Styled.PageWrapper>
    </Styled.Section>
  );
};

Footer.propTypes = {
  bg: PropTypes.string,
  contact: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hasRecaptcha: PropTypes.bool,
};

Footer.defaultProps = {
  bg: 'white',
  contact: (
    <span>
      Talk to us to customize the 100mentors experience for your community{' '}
      <a href="mailto:companies@100mentors.com">companies@100mentors.com</a>
    </span>
  ),
  hasRecaptcha: false,
};

export default Footer;
