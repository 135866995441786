import styled from 'styled-components';
import { display } from 'styled-system';
import { motion } from 'framer-motion';
import { InnerLink, InnerNavLink, NavLink } from 'src/components/Link';
import Menu, { MenuItem } from 'src/components/Menu';
import Button, { IconButton } from 'src/components/Button';
import { colors, media } from 'src/styles/theme';

export default {
  Nav: styled(motion.div)`
    width: 100%;
    z-index: 99;
    position: absolute;
    background-color: ${colors.white};
    height: 0vh;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: 5px 20px;
    background-color: ${({ isTop, mobileMenuOpen, background }) =>
      !isTop || mobileMenuOpen ? colors.white : background};
    box-shadow: ${({ isTop, mobileMenuOpen }) => !isTop && !mobileMenuOpen && '0 4px 40px rgb(0 0 0 / 8%)'};

    ${media.sm`
      padding: 20px 30px;
    `};
  `,
  LogoLink: styled(InnerLink)`
    display: flex;
    margin-right: 70px;

    &:hover {
      opacity: 0.85;
    }
  `,
  Menu: styled(Menu)`
    ${display};
    margin: 5px 0 0 0;
  `,
  MenuItem: styled(MenuItem)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 28px;
  `,
  MenuIndicator: styled(motion.div)`
    display: ${({ selected }) => (selected ? 'block' : 'none')};
    margin: 0 auto;
    position: relative;
    width: 0px;
    height: 2px;
    margin-top: 5px;
    background-color: ${colors.hmBrandYellow};
  `,
  SignUpButton: styled(Button)`
    margin-right: 15px;
  `,
  LogInButton: styled(IconButton)``,
  MobileMenuWrapper: styled(motion.div)`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: relative;
    width: 100%;
  `,
  MobileMenuItem: styled(motion.div)`
    margin: 0px 25px;
    opacity: 0;
    box-sizing: border-box;

    &:not(:last-child) {
      border-bottom: 1px solid ${colors.hmBlack};
    }
  `,
  MobileInnerNavLink: styled(InnerNavLink)`
    font-size: 17px !important;
    height: 44px;
    display: flex;
    align-items: center;
  `,
  MobileNavLink: styled(NavLink)`
    font-size: 17px !important;
    height: 44px;
    display: flex;
    align-items: center;
  `,
  BurgerButton: styled.button`
    margin-right: -10px !important;
    &:focus {
      outline: none;
    }
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: ${colors.hmBlack} !important;
    }

    &:not(.is-active) {
      .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
        background-color: ${({ isTop, whiteBg }) => (isTop && !whiteBg ? 'white' : colors.hmBlack)} !important;
      }
    }
  `,
};
