import styled from 'styled-components';
import { media } from 'src/styles/theme';
import { CircleButton } from 'src/components/Button';

export default {
  Container: styled.div`
    display: flex;
    align-items: center;
    max-width: 600px;
    height: 346px;
    margin: 0 auto;

    ${media.sm`
      height: 458px;
    `};
  `,
  CircleButtonLeft: styled(CircleButton)`
    transform: scaleX(-1);
    width: 43px;
    margin: -20px -10px 0 5px;
    pointer-events: auto;
    z-index: 3;
    box-shadow: 0px 0px 5px 1px rgba(62, 62, 62, 0.2);
  `,
  CircleButtonRight: styled(CircleButton)`
    width: 43px;
    margin: -20px 5px 0 -10px;
    pointer-events: auto;
    z-index: 3;
    box-shadow: 0px 0px 5px 1px rgba(62, 62, 62, 0.2);
  `,
};
