import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FluidImage } from 'src/components/Image';

import S from './bootcamp.Certification.Styled';

export const CertificationBootcamp = () => {
  const { certificateFront, certificateBack } = useStaticQuery(graphql`
    query {
      certificateFront: file(relativePath: { eq: "bootcamp/certificate-front.png" }) {
        childImageSharp {
          fluid(maxWidth: 510) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      certificateBack: file(relativePath: { eq: "bootcamp/certificate-back.png" }) {
        childImageSharp {
          fluid(maxWidth: 510) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <S.Section>
      <S.PageWrapper>
        <S.Heading>Certification</S.Heading>
        <S.SubHeading>Learn how to apply AI in your daily workflows and become AI-Empowered!</S.SubHeading>
        <S.Content>
          <S.ImageWrapper>
            <FluidImage image={certificateFront} alt="Certificate front" />
          </S.ImageWrapper>
          <S.ImageWrapper>
            <FluidImage image={certificateBack} alt="Certificate back" />
          </S.ImageWrapper>
        </S.Content>
      </S.PageWrapper>
    </S.Section>
  );
};
