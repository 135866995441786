import styled from 'styled-components';
import Section from 'src/components/Section';
import Wrapper from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import { colors, media, font } from 'src/styles/theme';

export default {
  HeadSection: styled(Section)`
    background-image: linear-gradient(135deg, #ac2fde 0%, #5235c9 100%);
  `,
  PageWrapper: styled(Wrapper)`
    min-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 80px 20px;
    box-sizing: border-box;

    ${media.lg`
      padding: 20px 20px 0 20px;
    `}
  `,
  Logo: styled.img`
    width: 165px;
    margin: 10px;
    align-self: flex-start;
  `,
  Content: styled.div`
    width: 100%;
    max-width: 1140px;
    margin-top: 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    ${media.lg`
      height: 440px;  
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: space-between;
    `}
  `,
  TextContainer: styled.div`
    max-width: 660px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;

    ${media.lg`
      align-items: flex-start;
    `}
  `,
  LogoContainer: styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    color: ${colors.white};
    text-transform: uppercase;
    font-size: ${font.fontSizes.s};
    font-weight: ${font.fontWeights.bold};

    ${media.lg`
      justify-content: flex-start;
    `}
  `,
  BrownLogo: styled.img`
    height: 38px;
    margin: 0 8px 2px 8px;
  `,
  MentorsLogo: styled.img`
    height: 15px;
    margin: 0 8px 2px 8px;
  `,
  Heading: styled(Heading)`
    margin-top: 20px;
    color: ${colors.white};
    text-align: center;
    font-size: 38px;
    font-weight: ${font.fontWeights.bold};

    ${media.lg`
      margin-top: 7px;
      text-align: left;
    `}
  `,
  Description: styled.div`
    margin-top: 20px;
    color: ${colors.white};
    text-align: center;
    font-weight: ${font.fontWeights.light};

    ${media.lg`
      text-align: left;
    `}
  `,
  BreakLine: styled.br`
    display: none;

    ${media.md`
      display: block;
    `}
  `,
  StatsContainer: styled.div`
    width: 100%;
    max-width: 360px;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;

    ${media.lg`
      margin-top: 30px;
    `}
  `,
  StatItem: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Stat: styled.div`
    color: ${colors.hmBrandYellow};
    font-size: ${font.fontSizes.xxl};
    font-weight: ${font.fontWeights.bold};
  `,
  Metric: styled.div`
    color: ${colors.white};
    margin-top: 5px;
    text-transform: uppercase;
  `,
  ImageContainer: styled.div`
    display: none;

    ${media.lg`
      display: flex;
      justify-content: center;
      align-items: start;
      width: 380px;
      height: 100%;
    `}
  `,
};
