export default {
  list: [
    {
      name: 'Yiorgos',
      photo: 'nikoletakisPhoto',
      position: 'CEO / Founder',
    },
    {
      name: 'Miltiadis',
      photo: 'zeibekisPhoto',
      position: 'CTO / Co-founder',
    },
    {
      name: 'Timos',
      photo: 'tokouzbalidesPhoto',
      position: 'CDO / Co-Founder',
    },
    {
      name: 'George',
      photo: 'sofianosPhoto',
      position: 'Principal Software Engineer',
    },
    {
      name: 'Pepy',
      photo: 'meliPhoto',
      position: 'Head of Learning Experience & Research',
    },
    {
      name: 'George',
      photo: 'papazoglouPhoto',
      position: 'Software Engineer',
    },
    {
      name: 'Chrysa',
      photo: 'chrysaPhoto',
      position: 'Digital Marketing Associate',
    },
    {
      name: 'Christina Lisa',
      photo: 'christinaLisaPhoto',
      position: 'Digital Communications Designer',
    },
    {
      name: 'Christos',
      photo: 'katsionisPhoto',
      position: 'HR Manager',
    },
    {
      name: 'Panagiotis',
      photo: 'varouktsisPhoto',
      position: 'Full Stack Developer',
    },
    {
      name: 'Stefania',
      photo: 'tegouPhoto',
      position: 'Customer Success Team Lead',
    },
    {
      name: 'Stavros',
      photo: 'nikoletopoulosPhoto',
      position: 'Full Stack Developer',
    },
    {
      name: 'Dimitris',
      photo: 'pantazatosPhoto',
      position: 'Research & Learning Analytics Associate',
    },
    {
      name: 'Andreas',
      photo: 'noukePhoto',
      position: 'Customer Success Consultant',
    },
    {
      name: 'Joanna',
      photo: 'joannaPhoto',
      position: 'Instructional Designer & Content Creator',
    },
    {
      name: 'Diamantis',
      photo: 'diamantisPhoto',
      position: 'Data Analysis Associate',
    },
    {
      name: 'George',
      photo: 'pappasPhoto',
      position: 'Product Designer',
    },
    {
      name: 'Antonis',
      photo: 'antonisPhoto',
      position: 'GenAI Products, Head of Growth',
    },
    {
      name: 'Yiannis',
      photo: 'stamatonikolosPhoto',
      position: 'BD & Sales Associate, GenAI Summit Curator',
    },
    {
      name: 'Maria Eleni',
      photo: 'mariaEleniPhoto',
      position: 'People Operations & Organizational Designer',
    },
    {
      name: 'Vasiliki Nefeli',
      photo: 'nefeliPhoto',
      position: 'Customer Success Representative',
    },
    {
      name: 'Christina',
      photo: 'triantafyllouPhoto',
      position: 'Project Manager',
    },
    {
      name: 'Eliza',
      photo: 'elizaPhoto',
      position: 'Operations Manager',
    },
    {
      name: 'Markella',
      photo: 'markellaPhoto',
      position: 'Product Quality Owner',
    },
    {
      name: 'Eva',
      photo: 'evaPhoto',
      position: 'BD & Sales Associate',
    },
  ],
};
