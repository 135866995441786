import React from 'react';
import PropTypes from 'prop-types';
import { FixedImage } from 'src/components/Image';
import Heading from 'src/components/Heading';

import S from './brown.LearningCommunities.Styled';

export const LearningCommunitiesBrown = ({ logos, communities }) => (
  <S.CommunitiesWrapper>
    <S.Communities>
      {logos.map(logo => (
        <FixedImage key={logo.childImageSharp.fixed.src} image={logo} alt="" />
      ))}
    </S.Communities>
    {communities && (
      <S.Communities>
        {communities.map(community => (
          <div key={community.name}>
            <Heading size={3}>{community.name}</Heading>
            <Heading size={3} color="hmGrey3">
              {community.type}
            </Heading>
          </div>
        ))}
      </S.Communities>
    )}
  </S.CommunitiesWrapper>
);

LearningCommunitiesBrown.propTypes = {
  logos: PropTypes.array.isRequired,
  communities: PropTypes.array,
};

LearningCommunitiesBrown.defaultProps = {
  communities: null,
};
