import { useState, useEffect } from 'react';
import axios from 'axios';

export const isBuilding = typeof window === 'undefined';

export function useAPIData(path) {
  const [data, setData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await axios.get(`${process.env.GATSBY_SERVER_URL}/api/${path}`);
        setData(results.data);
      } catch (err) {
        setError(err);
      }
      setIsLoaded(true);
    };

    fetchData();
  }, [path]);

  return [data, isLoaded, error];
}

export function useMultipleAPIData(paths) {
  const [data, setData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const fetchPromises = paths.map(path => axios.get(`${process.env.GATSBY_SERVER_URL}/api/${path}`));

      try {
        const results = await Promise.all(fetchPromises);
        setData(results.map(result => result.data));
      } catch (err) {
        setError(err);
      }
      setIsLoaded(true);
    };

    fetchData();
  }, [paths]);

  return [data, isLoaded, error];
}

// ex. const deviceDetect = useMobileDetect()
//     deviceDetect.isMobile()
export const useMobileDetect = () => {
  const getMobileDetect = userAgent => {
    const isAndroid = () => Boolean(userAgent.match(/Android/i));
    const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
    const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
    const isWindows = () => Boolean(userAgent.match(/IEMobile/i));

    const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows());
    const isDesktop = () => !isMobile();
    return {
      isMobile,
      isDesktop,
      isAndroid,
      isIos,
    };
  };
  useEffect(() => {}, []);
  if (typeof window !== 'undefined') {
    return getMobileDetect(navigator.userAgent);
  }

  return null;
};

export function useLocalStorage(key, initialValue) {
  const localStorageExist = !isBuilding && window.localStorage;
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (!localStorageExist) return initialValue;
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    if (!localStorageExist) return;
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(!isBuilding && window.innerWidth);
  useEffect(() => {
    const onWindowResize = () => {
      if (!isBuilding) setWindowWidth(window.innerWidth);
    };
    if (!isBuilding) window.addEventListener('resize', onWindowResize);
    return () => {
      if (!isBuilding) window.removeEventListener('resize', onWindowResize);
    };
  });
  return windowWidth;
};
