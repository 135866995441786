import colors from './colors';
import font from './font';
import breakPoints from './breakpoints';
import media from './media';

// Breakpoints as an array to be compatible with styled-system
const breakpoints = [breakPoints.sm.min, breakPoints.md.min, breakPoints.lg.min];

const zIndex = {
  backgroundBottom: 0, // Main Background
  backgroundMiddle: 1, // Background Elements On Top Of Other Backgrounds
  backgroundTop: 2, // Top Level Background Elements
  foregroundBottom: 3, // Foreground Elements
  foregroundMiddle: 4,
  foregroundTop: 5,
};

export default { colors, ...font, breakpoints, zIndex };
export { breakPoints, colors, font, media };
