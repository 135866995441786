import styled from 'styled-components';
import { PageWrapper } from 'src/components/Wrapper';
import Section from 'src/components/Section';
import { media } from 'src/styles/theme';

export default {
  Section: styled(Section)`
    position: relative;
    width: 100%;
    min-width: 350px;
  `,
  PageWrapper: styled(PageWrapper)`
    position: relative;
  `,
  DartImage: styled.div`
    position: absolute;
    top: 20px;
    right: 10px;
    z-index: 1;

    img {
      max-width: 120px;
    }

    ${media.sm`
      display: none;
    `};

    ${media.md`
      display: block;
      top: 22%;
      right: 20px;
      width: 200px;

      img {
        max-width: none;
      }
    `};

    @media (min-width: 900px, max-width: 950px) {
      display: block;
      top: 25%;
      right: 0px;
      width: 200px;

      img {
        max-width: none;
      }
    }

    ${media.lg`
      display: block;
      top: 20%;
      right: 100px;
      width: 200px;

      img {
        max-width: none;
      }
    `};
  `,
  DartLineImage: styled.div`
    position: absolute;
    top: 120px;
    right: -10px;
    z-index: 0;

    ${media.xs`
      top: 100px;
      right: -5px;
    `};

    ${media.sm`
      display: none;
    `};

    ${media.md`
      display: block;
      top: 33%;
      right: 20%;
      transform: rotate(230deg); 
    `};

    @media (min-width: 900px, max-width: 950px) {
      display: block;
      top: 35%;
      right: 22%;
      transform: rotate(230deg);
    }

    ${media.lg`
      display: block;
      top: 31%;
      right: 25%;
      transform: rotate(230deg); 
    `};
  `,
  Content: styled.div``,
  HeadContainer: styled.div`
    margin: 0px auto;
    max-width: 650px;

    ${media.md`
      max-width: 730px;
      margin: 0px 0px;
    `}
  `,
  BigText: styled.div`
    width: 100%;
    color: #3e3e3ecc;
    font-size: 15px;
    font-weight: 500;
    font-style: italic;

    ${media.sm`
      font-size: 29px;
      padding-right: 20px;
      box-sizing: border-box;
    `};
  `,
  SmallText: styled.div`
    color: #3e3e3ecc;
    font-size: 13px;
    margin-top: 8px;

    ${media.sm`
      font-size: 20px;
      font-weight: 500;
      margin-top: 15px;
    `};
  `,
  Body: styled.div`
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 30px;

    ${media.md`
      flex-flow: row nowrap;
      margin-top: 60px;
    `};
  `,
  Image: styled.div`
    max-width: 564px;
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  `,
};
