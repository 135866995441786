import styled from 'styled-components';
import Section from 'src/components/Section';
import { PageWrapper } from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import FontIcon from 'src/components/FontIcon';
import { colors, font, media } from 'src/styles/theme';

export default {
  Section: styled(Section)`
    background-color: ${colors.paleGrey};
  `,
  PageWrapper: styled(PageWrapper)`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    padding: 60px 10px;

    ${media.sm`
      padding: 80px 20px;
    `};
  `,
  Heading: styled(Heading)`
    margin-bottom: 50px;
  `,
  StepContainer: styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    > :not(:first-child) {
      margin-top: 25px;
    }
  `,
  Step: styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    max-width: 650px;
  `,
  IconContainer: styled.div`
    background-color: ${colors.hmOrange}0d;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 14px;
    border: 2px solid ${colors.hmOrange};
    border-radius: 50%;
  `,
  Index: styled.div`
    background-color: ${colors.hmOrange};
    color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    width: 24px;
    aspect-ratio: 1;
    font-size: ${font.fontSizes.l};
    font-weight: 700;
    transform: translate(0, -50%);
    border-radius: 50%;

    ${media.sm`
      top: 0px;
      left: 0px;
      transform: translate(-60%, calc(50% + 5px));
    `}
  `,
  DescriptionContainer: styled.div`
    flex: 1;
    font-size: ${font.fontSizes.l};
    font-weight: ${font.fontWeights.light};

    > p {
      all: unset;
    }
  `,
  DurationContainer: styled.div`
    flex: 0.15;
    display: flex;
    align-items: center;
    justify-content: start;

    ${media.sm`
      margin-left: 20px;
    `}
  `,
  FontIcon: styled(FontIcon)`
    color: ${colors.hmOrange};
  `,
  Duration: styled.div`
    font-size: 20px;
  `,
  Vector: styled.div`
    display: none;
    position: absolute;
    top: -257px;
    left: 32px;
    width: 54px;
    height: 256px;
    border-top: 1px solid ${colors.hmOrange};
    border-left: 1px solid ${colors.hmOrange};
    background-color: transparent;

    @media screen and (min-width: 870px) {
      display: block;
    }
  `,
  SyllabusContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 940px;
    margin-top: 50px;
    padding: 10px;
    border: 1px solid ${colors.hmOrange};
    border-radius: 25px;
    background-color: ${colors.white};

    @media screen and (min-width: 870px) {
      padding: 20px;
    }
  `,
  SyllabusTitle: styled(Heading)`
    display: flex;
    align-items: center;
    margin: 50px 0;
    white-space: nowrap;
    color: ${colors.hmOrange};
  `,
  Number: styled.img`
    width: 26px;
    height: 26px;
    margin-right: 5px;
  `,
  SyllabusContent: styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 870px) {
      flex-direction: row;
    }
  `,
  Menu: styled.div`
    margin-bottom: 10px;
    & > :not(:last-child) {
      margin-bottom: 5px;
    }

    & > div:first-child {
      margin-left: 10px;
    }
  `,
  Title: styled.div`
    text-transform: uppercase;
    color: ${colors.hmGrey2};
    font-weight: 700;
    font-size: 16px;
  `,
  Option: styled.div`
    font-weight: 700;
    padding: 10px;
    border-radius: 30px;
    width: fit-content;
    background-color: ${({ disabled }) => (disabled ? colors.white : colors.hmOrange)};
    color: ${({ disabled }) => (disabled ? colors.hmGrey2 : colors.white)};
    border: 1px solid ${({ disabled }) => (disabled ? colors.hmGrey2 : colors.hmOrange)};
  `,
  Thread: styled.div`
    padding: 10px;
    color: ${({ active }) => (active ? colors.hmOrange : colors.hmGrey2)};
    border-left: ${({ active }) => (active ? `3px solid ${colors.hmOrange}` : `1px solid ${colors.hmGrey1}`)};
  `,
  Divider: styled.div`
    display: none;
    width: 1px;
    height: 552px;
    margin: 0 20px 0 10px;
    background-color: ${colors.hmGrey1};

    @media screen and (min-width: 870px) {
      display: block;
    }
  `,
  Syllabus: styled.div`
    margin: 50px 0;
    padding: 30px 20px;
    border: 1px solid ${colors.hmGrey1};
    border-radius: 2px;

    @media screen and (min-width: 870px) {
      margin: 0;
    }
  `,
  ThreadTitle: styled.div`
    margin: 5px 0 30px 0;
  `,
  Example: styled.div`
    display: flex;
    align-items: center;
    margin: 5px 0;
    border: 1px solid ${colors.hmGrey1};
    border-radius: 20px;
    max-width: 422px;
    width: 100%;
    height: 135px;
  `,
  ExampleIcon: styled.img`
    height: 78px;
  `,
  ExampleTitle: styled.div`
    text-transform: uppercase;
    font-weight: 700;
  `,
  ExampleText: styled.div`
    font-size: 24px;
    font-weight: 300;
  `,
  ExampleDefinition: styled.div`
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    color: ${colors.hmGrey3};
  `,
  ExampleLink: styled.a`
    text-decoration: none;
    text-transform: uppercase;
    color: ${colors.hmBlue};
    font-weight: 700;
    font-size: 14px;
  `,
  ExampleLinkButton: styled.div`
    margin-top: 30px;
    width: fit-content;
    background: rgba(6, 141, 235, 0.1);
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    transition: background-color 0.25s linear;

    &:hover {
      background: rgba(6, 141, 235, 0.3);
    }
  `,
  LinkIcon: styled.img`
    margin-left: 5px;
    height: 15px;
    width: 15px;
  `,
};
