import styled, { keyframes } from 'styled-components';
import Section from 'src/components/Section';
import { PageWrapper } from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import theme, { colors, media } from 'src/styles/theme';

const floatAnimation = keyframes`
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 8px); }
    100%   { transform: translate(0, -0px); }
`;

const MetaImage = styled.div`
  overflow: hidden;
  border-radius: 18px;
  cursor: pointer;
  object-fit: cover;
  height: 100%;
  width: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  ${media.sm`
    height: 658px;
    img {
      max-width: inherit;
      max-height: inherit;
    }
  `};
`;

const MetaImageLeft = styled(MetaImage)`
  width: 100%;

  ${media.sm`
    width: 300px;
  `};
`;
const MetaImageRight = styled(MetaImage)`
  width: 100%;

  ${media.sm`
    width: 170px;
  `};
`;

const MetaIcon = styled.div`
  position: absolute;
  animation: ${floatAnimation} 5s ease-in-out infinite;
  z-index: 1;
  pointer-events: none;
`;

const MetaIconCreativity = styled(MetaIcon)`
  left: 50%;
  top: 2%;
  xanimation-delay: 2s;

  ${media.md`
    left: -95px;
    top: 118px;
  `};
`;

const MetaIconProblemSolving = styled(MetaIcon)`
  right: 24%;
  top: 40%;
  xanimation-delay: 1s;

  ${media.md`
    right: 140px;
    top: 275px;
  `};
`;

const MetaIconCriticalThinking = styled(MetaIcon)`
  right: 90%;
  bottom: 20%;

  ${media.md`
    right: 460px;
    bottom: 170px;
  `};
`;

const MetaLabel = styled.div`
  display: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  height: 60px;
  width: 221px;
  transform-origin: bottom left;
  bottom: -10px;
  left: 40px;
  white-space: nowrap;
  font-size: 22px;
  color: white;

  background: ${({ color }) => color};
  border-radius: 0px 15px;
  pointer-events: none;

  transform: rotate(270deg) translate(0px, 10px) scale(0.9);
  left: 100%;
  bottom: 6%;

  ${media.md`
    transform: rotate(270deg) translate(0px,10px) scale(1.0);
  `};
`;

export default {
  HeadSection: styled(Section)`
    position: relative;
    overflow: hidden;

    font-size: 14px;
    ${media.sm`
      font-size: 18px;
    `};
  `,
  PageWrapper: styled(PageWrapper)`
    padding: 110px 20px 60px 20px;

    ${media.sm`
      padding: 135px 20px 70px 20px;
    `};
  `,
  Content: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    ${media.md`
      flex-wrap: nowrap;
    `};
  `,
  Body: styled.div`
    ${media.md`
      margin-right: 45px;
    `};
  `,
  Background: styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  `,
  Text: styled.div`
    color: ${colors.hmGrey3};
    max-width: 650px;
    ${media.md`
      max-width: 540px;
    `};
  `,
  Title: styled(Heading)`
    font-size: 42px;
    font-weight: ${theme.fontWeights.bold};
    margin: 0 auto 20px auto;
    position: relative;
    line-height: 45px;

    ${media.md`
      font-size: 60px;
      line-height: 63px;
    `};

    ${media.lg`
      font-size: 86px;
      line-height: 93px;
    `};
  `,
  Typed: styled.div`
    font-size: 20px;
    font-weight: 400;
    margin-top: 25px;
    margin-bottom: 30px;

    ${media.md`
      font-size: 24px;
    `};

    ${media.lg`
      font-size: 28px;
    `};
  `,
  Meta: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
  `,
  MetaLabelContainer: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  `,
  MetaLabel,
  MetaItem: styled.div`
    cursor: pointer;
    position: relative;
    &:hover {
      img {
        transform: scale(1.05);
      }

      ${MetaLabel} {
        bottom: 0%;
        transform: rotate(270deg) translate(0px, 10px) scale(1.16);
      }
    }

    & > ${MetaImage} > img,
    & ${MetaLabel} {
      transition: all 0.3s ease;
    }
  `,
  MetaImage,
  MetaImageLeft,
  MetaImageRight,
  MetaIcon,
  MetaIconCreativity,
  MetaIconProblemSolving,
  MetaIconCriticalThinking,
  MetaLine: styled.div`
    display: none;
    ${media.md`
      display: block;
      position: absolute;
      left: -181px;
      top: -170px;
      z-index: -2;
      pointer-events: none;
    `};
  `,
  ButtonContainer: styled.div`
    width: 100%;
    max-width: 250px;
    margin: 40px auto 35px auto;

    ${media.md`
      max-width: 280px;
      margin: 40px 0px 0px 0px;
    `};
  `,
};
