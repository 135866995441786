import styled from 'styled-components';
import { transparentize } from 'polished';
import theme, { colors } from '../../styles/theme';

const NavLink = styled.a`
  font-size: ${theme.fontSizes.l};
  color: ${({ color }) => colors[color]};
  text-shadow: ${({ color }) => (color === 'white' ? `0 1px 2px ${colors.hmBrandPurple50}` : 'none')};
  text-decoration: none;

  &:hover {
    color: ${({ color }) => transparentize(0.2, colors[color])};
  }
`;

NavLink.defaultProps = {
  color: 'white',
};

export default NavLink;
