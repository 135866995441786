import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Footer from 'src/components/Footer';
import Layout from 'src/components/Layout';
import Nav from 'src/components/Nav';
import MainHelmet from '../components/MainHelmet';
import HeadSection from '../page-components/about/HeadSection';
import OurWhySection from '../page-components/about/OurWhySection';
import MeetTheTeamSection from '../page-components/about/MeetTheTeamSection';
import metaImage from '../assets/images/about/metaImage.png';

const About = ({ data }) => (
  <Layout>
    <MainHelmet addonTitle="About" meta={{ image: metaImage }} />
    <Nav />
    <HeadSection />
    <OurWhySection />
    <MeetTheTeamSection data={data} />
    <Footer bg="paleGrey" />
  </Layout>
);

About.propTypes = {
  data: PropTypes.shape({
    headerImage: PropTypes.shape({ childImageSharp: PropTypes.shape({ fluid: Img.propTypes.fluid }) }),
  }).isRequired,
};

export const query = graphql`
  query {
    nikoletakisPhoto: file(relativePath: { eq: "about/team-pictures/nikoletakis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    zeibekisPhoto: file(relativePath: { eq: "about/team-pictures/zeibekis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tokouzbalidesPhoto: file(relativePath: { eq: "about/team-pictures/tokouzbalides.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    sofianosPhoto: file(relativePath: { eq: "about/team-pictures/yiorgos-sofianos.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    meliPhoto: file(relativePath: { eq: "about/team-pictures/pepy-meli.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    papazoglouPhoto: file(relativePath: { eq: "about/team-pictures/george-papazoglou.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    chrysaPhoto: file(relativePath: { eq: "about/team-pictures/chrysa-benou.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    christinaLisaPhoto: file(relativePath: { eq: "about/team-pictures/christinaLisa.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    katsionisPhoto: file(relativePath: { eq: "about/team-pictures/christos-katsionis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    varouktsisPhoto: file(relativePath: { eq: "about/team-pictures/panagiotis-varouktsis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tegouPhoto: file(relativePath: { eq: "about/team-pictures/stefania-tegou.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    nikoletopoulosPhoto: file(relativePath: { eq: "about/team-pictures/stavros-nikoletopoulos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    pantazatosPhoto: file(relativePath: { eq: "about/team-pictures/dimitris-pantazatos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    noukePhoto: file(relativePath: { eq: "about/team-pictures/andreas-nouke.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    joannaPhoto: file(relativePath: { eq: "about/team-pictures/joanna-taouki.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    diamantisPhoto: file(relativePath: { eq: "about/team-pictures/dimantis-panagiotidis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    pappasPhoto: file(relativePath: { eq: "about/team-pictures/pappas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    antonisPhoto: file(relativePath: { eq: "about/team-pictures/antonis.png" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    stamatonikolosPhoto: file(relativePath: { eq: "about/team-pictures/stamatonikolos.png" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mariaEleniPhoto: file(relativePath: { eq: "about/team-pictures/maria-eleni.png" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    nefeliPhoto: file(relativePath: { eq: "about/team-pictures/nefeli.png" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    triantafyllouPhoto: file(relativePath: { eq: "about/team-pictures/triantafyllou.png" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    elizaPhoto: file(relativePath: { eq: "about/team-pictures/eliza.png" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    markellaPhoto: file(relativePath: { eq: "about/team-pictures/markella.png" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    evaPhoto: file(relativePath: { eq: "about/team-pictures/eva.png" }) {
      childImageSharp {
        fluid(maxWidth: 120, maxHeight: 120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default About;
