/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import Styled from './MarkdownTemplate.Styled';

const MarkdownTemplate = ({ data }) => {
  const { html, frontmatter } = data;

  return (
    <Styled.Container>
      <Styled.Title>{frontmatter.title}</Styled.Title>
      <Styled.Updated>
        Last Updated: <strong>{frontmatter.date}</strong>
      </Styled.Updated>
      <Styled.Markdown dangerouslySetInnerHTML={{ __html: html }} />
    </Styled.Container>
  );
};

MarkdownTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MarkdownTemplate;
