export default {
  xs: {
    /* Mobile Portrait - Landscape */
    min: '0px',
    max: '599px',
  },
  sm: {
    /* Tablet Portrait */
    min: '600px',
    max: '899px',
  },
  md: {
    /* Tablet Landscape */
    min: '900px',
    max: '1199px',
  },
  lg: {
    /* Desktop */
    min: '1200px',
    max: '1919px',
  },
  xlg: {
    /* Large Desktop */
    min: '1920px',
    max: '9999px',
  },
};
