import React from 'react';
import PropTypes from 'prop-types';
import { PageWrapper } from 'src/components/Wrapper';
import schoolsIcon from 'src/assets/images/home/schools-icon.svg';
import mentorsIcon from 'src/assets/images/home/mentors-icon.svg';
import companiesIcon from 'src/assets/images/home/companies-icon.svg';
import learnersIcon from 'src/assets/images/home/learners-icon.svg';
import arrow from 'src/assets/images/home/arrow-right.svg';
import S from './PersonasSection.Styled';

const personas = [
  {
    icon: schoolsIcon,
    title: 'Schools',
    description: 'Harness the power of questioning in your classroom with classroom-friendly tech.',
    link: '/schools',
  },
  {
    icon: companiesIcon,
    title: 'Companies',
    description: 'Engage your employees, create a culture of questioning, and measure impact. ',
    link: '/companies',
  },
  {
    icon: mentorsIcon,
    title: 'Mentors',
    description: 'Share your expertise and inspire young minds, 100 seconds at a time.',
    link: '/mentors',
  },
];

const Persona = ({ icon, title, description, link }) => (
  <S.PersonaButtonCard to={link}>
    <S.Icon src={icon} alt={title} />
    <S.TextContainer>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
    </S.TextContainer>
    <S.ArrowIcon src={arrow} alt={`Link for the ${title} site`} />
  </S.PersonaButtonCard>
);

Persona.propTypes = {
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export const PersonasSection = () => (
  <S.PersonasSection>
    <PageWrapper>
      <S.Content>
        <S.Heading>Our community</S.Heading>
        <S.Personas>
          {personas.map(persona => (
            <Persona
              key={persona.title}
              icon={persona.icon}
              title={persona.title}
              description={persona.description}
              link={persona.link}
            />
          ))}
          <S.PersonaExternalButtonCard
            href="https://meet.100mentors.com/learners"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.Icon src={learnersIcon} alt="Learners" />
            <S.TextContainer>
              <S.Title>Learners</S.Title>
              <S.Description>
                Ask better questions, get personalized answers from the world’s brightest minds.
              </S.Description>
            </S.TextContainer>
            <S.ArrowIcon src={arrow} alt="Link for the Learners site" />
          </S.PersonaExternalButtonCard>
        </S.Personas>
      </S.Content>
    </PageWrapper>
  </S.PersonasSection>
);
