import styled from 'styled-components';
import Img from 'gatsby-image';
import Wrapper, { PageWrapper, AbsoluteWrapper } from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import { CircleButton } from 'src/components/Button';
import { Image } from 'src/components/Image';
import theme, { colors, font } from 'src/styles/theme';
import media from 'src/styles/theme/media';

export default {
  TestimonialsSection: styled.section`
    background: ${({ bgColor }) => colors[bgColor]};
  `,
  PageWrapper: styled(PageWrapper)`
    padding: 80px 0;
    text-align: center;
  `,
  SmallHeading: styled(Heading)`
    font-size: ${font.headingFontSizes.xs};
    font-weight: ${font.fontWeights.bold};
    color: ${colors.hmGrey3};
    margin-bottom: 20px;
  `,
  Heading: styled(Heading)`
    padding: 0 20px;
    margin-bottom: 10px;
  `,
  Description: styled.div`
    font-weight: ${font.fontWeights.light};
    max-width: 550px;
    padding: 0 20px;
    margin: 0 auto 40px auto;
    color: #000000;
  `,
  CarouselWrapper: styled(Wrapper)`
    position: relative;
    width: 100%;

    .slick-slide,
    .slick-slide * {
      outline: none !important;
    }
    .slick-track {
      display: flex;
      align-items: center;
    }
  `,
  ButtonsWrapper: styled(AbsoluteWrapper)`
    max-width: 800px;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: ${theme.zIndex.foregroundBottom};
    pointer-events: none;
  `,
  CircleButtonLeft: styled(CircleButton)`
    transform: scaleX(-1);
    position: absolute;
    left: 10px;
    top: 50%;
    pointer-events: auto;
  `,
  CircleButtonRight: styled(CircleButton)`
    position: absolute;
    right: 10px;
    top: 50%;
    pointer-events: auto;
  `,
  TestimonialCardWrapper: styled(Wrapper)`
    box-sizing: border-box;
    padding: 20px 30px;
  `,
  TestimonialCard: styled.div`
    position: relative;
    min-height: 270px;
    max-width: 620px;
    box-sizing: border-box;
    padding: 20px 40px;
    text-align: center;
    box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin: 0 auto;

    ${media.sm`
      padding: 20px 35px;
    `};
  `,
  CompanyLogo: styled(Img)`
    width: ${({ width }) => width};
    margin: 0 auto 25px auto;
  `,
  TestimonialContainer: styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  `,
  Quote: styled(Image)`
    width: 30px;
    margin: 0 auto 30px auto;
  `,
  Testimonial: styled.div`
    font-weight: ${font.fontWeights.light};
    font-style: italic;
    margin-bottom: 25px;
  `,
  Avatar: styled.img`
    width: 50px;
    height: 50px;
    margin: 0 auto 5px auto;
  `,
  Name: styled.div`
    font-family: ${font.fonts.lato};
    font-size: ${font.headingFontSizes.s};
    font-weight: ${font.fontWeights.bold};
    margin-bottom: 5px;
  `,
  Country: styled.div`
    font-weight: ${font.fontWeights.light};
    font-size: ${font.fontSizes.l};
  `,
};
