import React from 'react';
import Layout from 'src/components/Layout';
import Nav from 'src/components/Nav';
import MainHelmet from 'src/components/MainHelmet';
import { colors } from 'src/styles/theme';
import Section from 'src/components/Section';
import { PageWrapper } from 'src/components/Wrapper';

import CookiesContent from 'src/page-components/cookies/CookiesContent';

const Cookies = () => (
  <Layout hideFooter>
    <MainHelmet addonTitle="Cookie Policy" />
    <Section>
      <Nav background={colors.hmBrandPurple} />
      <PageWrapper p={['100px 20px !important', '120px 20px !important']}>
        <CookiesContent />
      </PageWrapper>
    </Section>
  </Layout>
);
export default Cookies;
