import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FluidImage } from 'src/components/Image';
import { font } from 'src/styles/theme';
import { InnerLink } from 'src/components/Link';
import Logo from 'src/components/Logo';

import S from './bootcamp.Hero.Styled';

const SuccessMessage = () => {
  return (
    <S.SuccessMessage>
      Thank you for your interest! <br />A 100mentors team member will be reaching out to you over the next couple of
      days to share more information.
    </S.SuccessMessage>
  );
};

const FailureMessage = () => {
  return <S.FailureMessage>Something went wrong, please try again.</S.FailureMessage>;
};

export const HeroBootcamp = () => {
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const { heroImage } = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "bootcamp/hero-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 570) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  function validateEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setResponseMessage('');
    const isValid = validateEmail();
    if (!isValid) {
      return;
    }

    const emailContactFormPostUrl = 'https://1hcnq26ysc.execute-api.eu-west-1.amazonaws.com/dev/emailContactForm';
    const submitWithToken = async token => {
      const data = {
        name: 'AI Marketing Bootcamp',
        email,
        message: 'I want to learn more about the bootcamp.',
        reCaptchaClientToken: token,
      };

      const response = await fetch(emailContactFormPostUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });

      if (response.status === 200) {
        setResponseMessage('Success');
        setEmail('');
      } else {
        setResponseMessage('Failed');
      }
    };

    window.grecaptcha.execute('6LcOLM0UAAAAACQPvGef65CrMHBdOeCegCILP0Z1', { action: 'homepage' }).then(submitWithToken);
  }

  return (
    <S.Header>
      <S.PageWrapper>
        <S.InnerLink to="/">
          <Logo colored />
        </S.InnerLink>
        <S.Content>
          <S.TextContainer>
            <S.PreHeading>Bootcamp:</S.PreHeading>
            <S.Heading>
              Your <S.OrangeText style={{ fontFamily: font.fonts.robotoMono }}>_MarketingTeam</S.OrangeText>,
              AI-EMPOWERED.
            </S.Heading>
            <S.SubHeading>Save an average of $11k per employee per year.</S.SubHeading>
            <S.CtaContainer>
              <S.Input placeholder="Type your e-mail" value={email} onChange={event => setEmail(event.target.value)} />
              <S.Button color="hmOrange" onClick={handleSubmit}>
                Learn More
              </S.Button>
            </S.CtaContainer>
            {responseMessage === 'Success' && <SuccessMessage />}
            {responseMessage === 'Failed' && <FailureMessage />}
          </S.TextContainer>
          <S.ImageWrapper>
            <FluidImage image={heroImage} alt="Hero Image" />
          </S.ImageWrapper>
        </S.Content>
      </S.PageWrapper>
    </S.Header>
  );
};
