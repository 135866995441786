import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import favicon from '../../assets/images/favicon.ico';
import metaMainImage from '../../assets/images/meta-image.jpg';

const MainHelmet = ({ addonTitle, meta }) => (
  <Helmet title={addonTitle ? `${addonTitle} | 100mentors` : '100mentors'}>
    <html lang="en" />
    <link rel="shortcut icon" type="image/ico" href={favicon} />
    <meta property="og:site_name" content="100mentors" />
    <meta property="og:title" content={addonTitle ? `${addonTitle} | 100mentors` : '100mentors'} />
    <meta
      property="og:description"
      content={
        meta && meta.description
          ? meta.description
          : 'Your people empowered by GenAI. Ask better questions. Build better prompts.'
      }
    />
    <meta property="og:image" content={meta && meta.image ? meta.image : metaMainImage} />
    <script src="https://www.google.com/recaptcha/api.js?render=6LcOLM0UAAAAACQPvGef65CrMHBdOeCegCILP0Z1" />
  </Helmet>
);

MainHelmet.propTypes = {
  addonTitle: PropTypes.string,
  meta: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
  }),
};

MainHelmet.defaultProps = {
  addonTitle: null,
  meta: null,
};

export default MainHelmet;
