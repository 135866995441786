import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { FluidImage } from 'src/components/Image';
import { colors } from 'src/styles/theme';

import S from './CompaniesSection.Styled';

const logos = {
  ey: {
    key: 'ey',
    image: 'ey',
    alt: 'EY Logo',
  },
  pwc: {
    key: 'pwc',
    image: 'pwc',
    alt: 'PWC Logo',
  },
  interamerican: {
    key: 'interamerican',
    image: 'interamerican',
    alt: 'Interamerican Logo',
  },
  cocaCola: {
    key: 'cocaCola',
    image: 'cocaCola',
    alt: 'Coca Cola Logo',
  },
  princesTrust: {
    key: 'princesTrust',
    image: 'princesTrust',
    alt: 'Princes Trust Logo',
  },
  brown: {
    key: 'brown',
    image: 'brown',
    alt: 'Brown Logo',
  },
};

const CompaniesSection = ({ backgroundColor, title, logos: logosProps }) => {
  const data = useStaticQuery(graphql`
    query {
      ey: file(relativePath: { eq: "companies/companies/ey.png" }) {
        childImageSharp {
          fluid(maxWidth: 110) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      interamerican: file(relativePath: { eq: "companies/companies/interamerican.png" }) {
        childImageSharp {
          fluid(maxWidth: 170) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      pwc: file(relativePath: { eq: "companies/companies/pwc.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      cocaCola: file(relativePath: { eq: "companies/companies/cocaCola.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      princesTrust: file(relativePath: { eq: "companies/companies/princesTrust.png" }) {
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      brown: file(relativePath: { eq: "companies/companies/brown.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <S.CompaniesSection backgroundColor={backgroundColor}>
      <S.PageWrapper>
        <S.Heading>{title}</S.Heading>
        <S.Container>
          {logosProps.map(logo => (
            <S.Logo key={logo.key} width={logo.width} opacity={logo.opacity}>
              <FluidImage image={data[logos[logo.key].image]} alt={logos[logo.key].alt} />
            </S.Logo>
          ))}
        </S.Container>
      </S.PageWrapper>
    </S.CompaniesSection>
  );
};

CompaniesSection.propTypes = {
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOf(Object.keys(logos)).isRequired,
      width: PropTypes.string.isRequired,
      opacity: PropTypes.number,
    }),
  ),
};

CompaniesSection.defaultProps = {
  backgroundColor: colors.paleGrey,
  title: 'Companies using 100mentors',
  logos: [
    {
      key: 'ey',
      width: '55px',
    },
    {
      key: 'interamerican',
      width: '85px',
    },
    {
      key: 'pwc',
      width: '70px',
    },
    {
      key: 'cocaCola',
      width: '110px',
    },
  ],
};

export default CompaniesSection;
