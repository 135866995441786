import styled from 'styled-components';
import Section from 'src/components/Section';
import Logo from 'src/components/Logo';
import { PageWrapper } from 'src/components/Wrapper';
import { InnerLink } from 'src/components/Link';
import media from 'src/styles/theme/media';
import theme, { colors } from 'src/styles/theme';

export default {
  FooterSection: styled(Section)`
    position: relative;
    background: ${({ bgColor }) => colors[bgColor]};
  `,
  LogoWrapper: styled(PageWrapper)`
    position: relative;
    padding: 0px 20px;
  `,
  Logo: styled(Logo)`
    display: block;
    position: absolute;
    top: -40px;
    width: 77px;
    border: 1px solid white;
  `,
  Wrapper: styled(PageWrapper)`
    padding: 80px 20px;
  `,
  InnerLink: styled(InnerLink)`
    font-size: ${theme.fontSizes.l};
    line-height: 28px;

    :hover {
      color: ${colors.hmBrandPurple};
    }
  `,
  HorizontalLine: styled.div`
    height: 2px;
    background: ${colors.hmGrey1};
    max-width: 100%;
    margin: 30px auto;
  `,
  CopyrightContainer: styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    font-size: 16px;
    width: 100%;
  `,
  Copyright: styled.div`
    width: 500px;
    margin-bottom: 10px;
    font-size: 18px;

    ${media.sm`
      margin-bottom: 0;
    `};
  `,
  TermsContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;
    color: ${colors.hmGrey3};

    ${media.sm`
      flex-direction: row;
      max-width: 500px;
    `};

    a:not(:last-child) {
      padding-right: 20px;
    }
  `,
};
