import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

export const FluidImage = ({ image, alt }) => <Img fluid={image.childImageSharp.fluid} alt={alt} />;

FluidImage.propTypes = {
  image: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
};
