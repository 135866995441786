import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import MainHelmet from '../components/MainHelmet';
import Button from '../components/Button';
import image404 from '../assets/images/404.jpg';
import logoImage from '../assets/images/100mentors-logo-white.svg';
import theme from '../styles/theme';

const GlobalStyle = createGlobalStyle`
    body {
        margin:0;
        font-family: ${theme.fonts.roboto};
        font-size: ${theme.fontSizes.xl};
        color: ${theme.colors.hmBlack};
        -webkit-font-smoothing: antialiased !important;
        background: #fcfbfd;
    }
`;

const Styled = {
  Content: styled.div`
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-image: url(${image404});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,

  Heading: styled.div`
    margin: 0 auto;
    text-align: center;
    font-size: 65px;
    letter-spacing: -3px;
    color: #fff;
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.35);
    font-weight: 500;

    padding: 0px 20px;
  `,
  SubText: styled.div`
    max-width: 710px;
    font-weight: 400;
    letter-spacing: -1px;
    font-size: 20px;
    line-height: 22px;
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.35);
    margin: 5px 0 40px;
    padding: 0;
    text-align: center;

    padding: 0px 20px;
  `,
  Logo: styled.div`
    position: absolute;
    left: 10px;
    bottom: 6px;
    width: 155px;
  `,
  BackButton: styled(Button)`
    padding: 16px 30px;
    width: 200px;
    font-size: 13px;
    color: ${theme.colors.hmBlack};

    box-shadow: 0 1px 3px 0 rgba(62, 62, 62, 0.8);
  `,
};

const NotFoundPage = () => (
  <Styled.Content>
    <MainHelmet addonTitle="Oops, we could not find what you are looking for?" />

    <Styled.Heading>There is nothing here</Styled.Heading>
    <Styled.SubText>
      The page you are looking for does not exist, unless you are looking for advice from rocks, in which case
      congratulations, this is the place for you!
    </Styled.SubText>
    <Styled.BackButton onClick={() => window.history.back()}>Go Back</Styled.BackButton>
    <Styled.Logo>
      <a href={process.env.GATSBY_SERVER_URL}>
        <img alt="100mentors logo" src={logoImage} />
      </a>
    </Styled.Logo>
    <GlobalStyle />
  </Styled.Content>
);

export default NotFoundPage;
