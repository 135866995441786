import styled from 'styled-components';
import Section from 'src/components/Section';
import { PageWrapper } from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import { media, font, colors } from 'src/styles/theme';

export default {
  CommunityTemplate: styled(Section)`
    background: ${({ bgColor }) => colors[bgColor]};
  `,
  PageWrapper: styled(PageWrapper)`
    padding: 80px 0;
    text-align: center;

    ${media.sm`
      padding: 80px 20px;
    `};
  `,
  Heading: styled(Heading)`
    margin-bottom: 20px;
    padding: 0 20px;
  `,
  SmallHeading: styled.div`
    font-weight: ${font.fontWeights.bold};
    font-size: ${font.fontSizes.s};
    text-transform: uppercase;
    color: ${colors.hmGrey3};
    margin-bottom: 40px;
    padding: 0 20px;

    ${media.md`
      margin-bottom: 20px;
    `}
  `,
  Description: styled.div`
    margin-bottom: 15px;
    padding: 0 20px;
  `,
};
