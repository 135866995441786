import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'src/components/Link';
import awarenessIcon from 'src/assets/images/bootcamp/step-icon-awareness.svg';
import opportunitiesIcon from 'src/assets/images/bootcamp/step-icon-opportunities.svg';
import risksAndLimitationsIcon from 'src/assets/images/bootcamp/step-icon-risks-and-limitations.svg';
import enterHumanIcon from 'src/assets/images/bootcamp/step-icon-enter-human.svg';
import timeExampleIcon from 'src/assets/images/bootcamp/time-example.svg';
import moneyExampleIcon from 'src/assets/images/bootcamp/money-example.svg';
import linkIcon from 'src/assets/images/bootcamp/linkIcon.svg';
import number from 'src/assets/images/bootcamp/number2.svg';

import S from './bootcamp.Modules.Styled';

const steps = [
  {
    key: 'awareness',
    icon: awarenessIcon,
    alt: 'Human side head brain',
    description: (
      <p>
        <strong>Awareness:</strong> Build a &quot;healthy relationship&quot; with AI. Why, how & when does it make sense
        to use it, in the context of my work?
      </p>
    ),
    duration: 15,
  },
  {
    key: 'opportunities',
    icon: opportunitiesIcon,
    alt: 'Hands receiving',
    description: (
      <p>
        <strong>Opportunities:</strong> Use cases with Prompts & Prompt Threads, in the context of your{' '}
        <span style={{ fontWeight: 'normal' }}>a) Team, b) Role, c) Workflows</span> (average of 10 minutes per Prompt
        Thread).{' '}
        <Link
          color="hmBlue"
          style={{ fontWeight: 'bold' }}
          href="https://hubs.ly/Q01BvKyB0"
          target="_blank"
          rel="noopener noreferrer"
        >
          See more
        </Link>
      </p>
    ),
    duration: 120,
  },
  {
    key: 'risks and limitations',
    icon: risksAndLimitationsIcon,
    alt: 'Warning sign',
    description: (
      <p>
        <strong>Risks & Limitations.</strong> Prompt use cases that generate misleading outputs. Learn how to use it as
        an AI-assistant (Vs a decision maker).
      </p>
    ),
    duration: 30,
  },
  {
    key: 'enter human',
    icon: enterHumanIcon,
    alt: 'Smiley face',
    description: (
      <p>
        <strong>“Enter Human”. When & How.</strong> Where and when do we need the human intervention in order to
        effectively use AI/ChatGPT?
      </p>
    ),
    duration: 20,
  },
];

const mainMenu = {
  title: 'role',
  options: [
    { label: 'Email Marketing', value: 'emailMarketing', disabled: false },
    { label: 'Social Media Marketing', value: 'socialMediaMarketing', disabled: true },
    { label: 'Content Marketing', value: 'contentMarketing', disabled: true },
  ],
};

const subMenus = {
  emailMarketing: {
    title: 'workflow',
    options: [
      { label: 'Strategy', value: 'strategy', disabled: false },
      { label: 'Design', value: 'design', disabled: true },
      { label: 'Organisation & Automation', value: 'organisation', disabled: true },
    ],
  },
};

const threads = {
  strategy: {
    title: 'prompt thread',
    options: [
      { label: 'Define your List Segmentation', value: 'segmentation', disabled: false },
      { label: 'Personalize your Emails', value: 'personalize', disabled: true },
      { label: 'Create an Email Timing Strategy', value: 'timingStrategy', disabled: true },
      { label: 'Create an Email Engagement Strategy', value: 'engagementStrategy', disabled: true },
    ],
  },
};

const Step = ({ index, icon, alt, description, duration }) => (
  <S.Step>
    <S.IconContainer>
      <S.Index>{index}</S.Index>
      <img src={icon} alt={alt} />
    </S.IconContainer>
    <S.DescriptionContainer>{description}</S.DescriptionContainer>
    <S.DurationContainer>
      <S.FontIcon name="clock" fontSize={25} />
      <S.Duration>{duration}&apos;</S.Duration>
    </S.DurationContainer>
  </S.Step>
);

Step.propTypes = {
  index: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  duration: PropTypes.number.isRequired,
};

const Menu = ({ title, options, onSelect }) => (
  <S.Menu>
    <S.Title>{title}:</S.Title>
    {options.map(option => (
      <S.Option
        key={option.value}
        onClick={() => !option.disabled && onSelect(option.value)}
        disabled={option.disabled}
      >
        {option.label}
      </S.Option>
    ))}
  </S.Menu>
);

Menu.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string, disabled: PropTypes.bool }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export const ModulesBootcamp = () => {
  const [mainSelected, setMainSelected] = useState('emailMarketing');
  const [subSelected, setSubSelected] = useState('strategy');

  return (
    <S.Section>
      <S.PageWrapper>
        <S.Heading>Bootcamp Modules</S.Heading>
        <S.StepContainer>
          {steps.map((step, index) => (
            <Step index={index + 1} {...step} />
          ))}
        </S.StepContainer>
        <S.SyllabusContainer>
          <S.SyllabusTitle>
            Module #<S.Number src={number} /> Syllabus
          </S.SyllabusTitle>
          <S.SyllabusContent>
            <S.Vector />
            <div>
              <Menu title={mainMenu.title} options={mainMenu.options} onSelect={setMainSelected} />
              <Menu
                title={subMenus[mainSelected].title}
                options={subMenus[mainSelected].options}
                onSelect={setSubSelected}
              />
              <div>
                <S.Title>{threads[subSelected].title}:</S.Title>
                {threads[subSelected].options.map(({ label, value, disabled }) => (
                  <S.Thread key={value} active={value === 'segmentation'} disabled={disabled}>
                    {label}
                  </S.Thread>
                ))}
              </div>
            </div>
            <S.Divider />
            <S.Syllabus>
              <S.Title>prompt thread:</S.Title>
              <S.ThreadTitle>Define your List Segmentation</S.ThreadTitle>
              <S.Title>Savings Example:</S.Title>
              <S.Example>
                <S.ExampleIcon style={{ paddingLeft: 10 }} src={timeExampleIcon} />
                <div style={{ marginLeft: 10 }}>
                  <S.ExampleTitle>time</S.ExampleTitle>
                  <S.ExampleText>16.5 days /year/employee</S.ExampleText>
                </div>
              </S.Example>
              <S.Example>
                <S.ExampleIcon style={{ paddingLeft: 20 }} src={moneyExampleIcon} />
                <div style={{ marginLeft: 25 }}>
                  <S.ExampleTitle>money</S.ExampleTitle>
                  <S.ExampleText>2,062 gross /year/employee*</S.ExampleText>
                </div>
              </S.Example>
              <S.ExampleDefinition>*annual gross salary: € 33,000</S.ExampleDefinition>
              <S.ExampleLinkButton>
                <S.ExampleLink href="https://hubs.ly/Q01BvHX00" target="_blank" rel="noopener noreferrer">
                  <div style={{ padding: 15 }}>
                    see example <S.LinkIcon src={linkIcon} />
                  </div>
                </S.ExampleLink>
              </S.ExampleLinkButton>
            </S.Syllabus>
          </S.SyllabusContent>
        </S.SyllabusContainer>
      </S.PageWrapper>
    </S.Section>
  );
};
