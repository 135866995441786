import styled from 'styled-components';
import { darken } from 'polished';
import Section from 'src/components/Section';
import Heading from 'src/components/Heading';
import { InnerLink } from 'src/components/Link';
import { media, colors, font } from 'src/styles/theme';

export default {
  PersonasSection: styled(Section)`
    background-color: ${colors.paleGrey};
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Heading: styled(Heading)`
    padding-bottom: 60px;
  `,
  Icon: styled.img`
    height: 40px;
    width: 50px;
    margin-bottom: 10px;

    ${media.sm`
      margin-bottom: 0;
    `};
  `,
  ArrowIcon: styled.img`
    height: 20px;
    width: 20px;
  `,
  TextContainer: styled.div`
    text-align: center;

    ${media.sm`
      text-align: left;
      margin-right: 45px;
    `};
  `,
  Title: styled(Heading)`
    font-weight: ${font.fontWeights.bold};
    font-size: ${font.headingFontSizes.s};
    margin-bottom: 10px;
  `,
  Description: styled.div`
    font-size: ${font.fontSizes.l};
    font-weight: ${font.fontWeights.light};
    max-width: 385px;
    margin-bottom: 20px;

    ${media.sm`
      margin-bottom: 0;
    `};
  `,
  Personas: styled.div`
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    max-width: 625px;
    width: 100%;
  `,
  PersonaButtonCard: styled(InnerLink)`
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
    padding: 30px;
    flex-direction: column;
    ${media.sm`
      padding: 15px;
      flex-direction: row;
    `};

    :hover {
      background-color: ${darken(0.03, colors.white)};
    }
  `,
  PersonaExternalButtonCard: styled.a`
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
    padding: 30px;
    flex-direction: column;
    text-decoration: none;
    color: ${colors.hmBlack};
    ${media.sm`
      padding: 15px;
      flex-direction: row;
    `};

    :hover {
      background-color: ${darken(0.03, colors.white)};
    }
  `,
};
