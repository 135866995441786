import React from 'react';
import Heading from '../../components/Heading';
import Section from '../../components/Section';
import Wrapper, { PageWrapper } from '../../components/Wrapper';
import Paragraph from '../../components/Paragraph';
import theme, { colors } from '../../styles/theme';
import Styled from './JobsOpeningSection.Styled';

const JobsOpeningSection = () => (
  <Section bg={colors.paleGrey}>
    <PageWrapper>
      <Wrapper>
        <Heading textAlign="center" mb="10px">
          Job Openings
        </Heading>
        <Wrapper maxWidth="750px" mx="auto" mb="40px">
          <Paragraph lineHeight="1.39" fontWeight={theme.fontWeights.light} textAlign="center">
            <Styled.NoJobsSubTitle>
              See active Job Openings on{' '}
              <Styled.EmailLink href="https://careers.100mentors.com" target="_blank">
                our Careers site
              </Styled.EmailLink>
            </Styled.NoJobsSubTitle>
          </Paragraph>
        </Wrapper>
      </Wrapper>
    </PageWrapper>
  </Section>
);

export default JobsOpeningSection;
