import React from 'react';
import PropTypes from 'prop-types';
import { FixedImage } from 'src/components/Image';

import S from './brown.MentorCommunity.Styled';

export const MentorCommunityBrown = ({ mentors }) => (
  <S.MentorsContainer>
    {mentors.map(mentor => (
      <S.Mentor
        key={mentor.id}
        href={`${process.env.GATSBY_SERVER_URL}/mentor/${mentor.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FixedImage image={mentor.photo} alt="" />
        <S.MentorName>{mentor.name}</S.MentorName>
      </S.Mentor>
    ))}
    <S.Mentor
      href={`${process.env.GATSBY_SERVER_URL}/community/communitystem`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <S.SeeMore>SEE MORE</S.SeeMore>
    </S.Mentor>
  </S.MentorsContainer>
);

MentorCommunityBrown.propTypes = {
  mentors: PropTypes.array.isRequired,
};
