import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import { useLocation } from '@reach/router';
import { createGlobalStyle } from 'styled-components';
import Headroom from 'react-headroom';
import Logo from 'src/components/Logo';
// import { InnerNavLink } from 'src/components/Link';
import Wrapper from 'src/components/Wrapper';
import { isBuilding } from 'src/utils';
import Styled from './Nav.Styled';
import 'src/assets/css/hamburgers.min.css';

export const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    overflow: ${({ mobileMenuOpen }) => (mobileMenuOpen ? 'hidden' : 'auto')}
  }
`;

// const navMenu = [
//   {
//     title: 'Schools',
//     href: '/schools',
//   },
//   {
//     title: 'Companies',
//     href: '/companies',
//   },
//   {
//     title: 'Mentors',
//     href: '/mentors',
//   },
// ];

const MobileMenu = ({ mobileMenuOpen }) => {
  const variants = {
    open: {
      x: 0,
      opacity: 1,
      transition: {
        ease: 'easeOut',
      },
    },
    closed: { x: '-100%', opacity: 0 },
  };

  return (
    <Styled.MobileMenuWrapper
      variants={{
        open: {
          x: 0,
          staggerChildren: 40,
          transition: { duration: 0.3 },
        },
        closed: {
          x: '-100vw',
          transition: { duration: 2 },
        },
      }}
      isOpen={mobileMenuOpen}
    >
      {/*
      {navMenu.map(({ title, href }) => (
        <Styled.MobileMenuItem variants={variants} key={title}>
          <Styled.MobileInnerNavLink to={href} color="hmBlack">
            {title}
          </Styled.MobileInnerNavLink>
        </Styled.MobileMenuItem>
      ))}
      */}
      <Styled.MobileMenuItem variants={variants}>
        <Styled.MobileNavLink href={`${process.env.GATSBY_SERVER_URL}/register`} color="hmBlack">
          Sign Up
        </Styled.MobileNavLink>
      </Styled.MobileMenuItem>
      <Styled.MobileMenuItem variants={variants}>
        <Styled.MobileNavLink href={`${process.env.GATSBY_SERVER_URL}/login`} color="hmBlack">
          Log In
        </Styled.MobileNavLink>
      </Styled.MobileMenuItem>
    </Styled.MobileMenuWrapper>
  );
};

MobileMenu.propTypes = {
  mobileMenuOpen: PropTypes.bool.isRequired,
};

const Nav = ({ className, background, logoColored }) => {
  // const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [hasUnpinned, setHasUnpinned] = useState(false);

  const toggleMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  const scrollAndToggleMenu = () => {
    if (!mobileMenuOpen && window && isTop) {
      window.scrollTo(0, 0);
    }
    toggleMenu();
  };

  // const isCurentLink = (link, isExternal) => {
  //   if (isExternal) return false;
  //   return location.pathname.startsWith(link);
  // };

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset === 0) {
        setIsTop(true);
        setHasUnpinned(false);
      } else if (hasUnpinned && window.pageYOffset > 90) {
        setIsTop(false);
      }
    });
  }

  return (
    <Styled.Nav
      className={className}
      animate={mobileMenuOpen ? 'open' : 'closed'}
      variants={{
        open: { height: `${!isBuilding && window.scrollY + 2000}px` },
        closed: { height: '0vh' },
      }}
    >
      <Headroom onUnpin={() => setHasUnpinned(true)}>
        <Styled.Content isTop={isTop} mobileMenuOpen={mobileMenuOpen} background={background}>
          <Styled.LogoLink to="/">
            <Logo colored={mobileMenuOpen || !isTop ? true : logoColored} />
          </Styled.LogoLink>

          {/*
          <Styled.Menu display={['none', 'none', 'flex']}>
            {navMenu.map(({ title, href }) => (
              <Styled.MenuItem key={title}>
                <InnerNavLink to={href} color={isTop && !logoColored ? 'white' : 'hmBlack'}>
                  {title}
                </InnerNavLink>
                <Styled.MenuIndicator
                  initial="exit"
                  animate="enter"
                  variants={{
                    enter: {
                      width: 30,
                    },
                    exit: {
                      width: 0,
                    },
                  }}
                  selected={isCurentLink(href)}
                />
              </Styled.MenuItem>
            ))}
          </Styled.Menu>
          */}

          <Wrapper display={['none', 'none', 'block']}>
            <Styled.SignUpButton
              color={isTop && !logoColored ? 'white' : 'hmBrandPurple'}
              href={`${process.env.GATSBY_SERVER_URL}/register`}
              rel="noopener noreferrer"
            >
              Sign Up
            </Styled.SignUpButton>
            <Styled.LogInButton
              iconName="arrow-right-bold"
              rel="noopener noreferrer"
              href={`${process.env.GATSBY_SERVER_URL}/login`}
              color={isTop && !logoColored ? 'white' : 'hmBrandPurple'}
              isTop={isTop}
            >
              Log In
            </Styled.LogInButton>
          </Wrapper>

          <Wrapper display={['block', 'block', 'none']}>
            <Styled.BurgerButton
              className={`hamburger hamburger--collapse ${mobileMenuOpen && 'is-active'}`}
              type="button"
              onClick={scrollAndToggleMenu}
              isTop={isTop}
              whiteBg={logoColored}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </Styled.BurgerButton>
          </Wrapper>
        </Styled.Content>

        <MobileMenu mobileMenuOpen={mobileMenuOpen} />
        <GlobalStyle mobileMenuOpen={mobileMenuOpen} />
      </Headroom>
    </Styled.Nav>
  );
};

Nav.propTypes = {
  className: PropTypes.string,
  background: PropTypes.string,
  logoColored: PropTypes.bool,
};

Nav.defaultProps = {
  className: '',
  background: 'none',
  logoColored: false,
};

export default Nav;
