import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'src/page-components/shared/Heading';
import arrowIcon from 'src/assets/images/home/studying-arrow.svg';
import bookIcon from 'src/assets/images/home/book-icon.svg';
import booksIcon from 'src/assets/images/home/books-icon.svg';
import summitIcon from 'src/assets/images/home/summit-icon.svg';
import tickIcon from 'src/assets/images/home/tick-icon.svg';
import linArrow from 'src/assets/images/home/shape.svg';
import walletIcon from 'src/assets/images/home/wallet.svg';
import laptopImage from 'src/assets/images/home/laptop.png';
import { colors } from 'src/styles/theme';
import FontIcon from 'src/components/FontIcon';

import S from './LearnByStudyingSection.Styled';

const Card = ({ link, icon, title, description, extra, steps, learnMoreTitle, isInsideLink }) => (
  <S.Link href={link} target={isInsideLink ? undefined : '_blank'}>
    <S.Card disabled={!link}>
      <S.Icon src={icon} />
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
      {extra && <S.Extra>{extra}</S.Extra>}
      {steps.map(step => (
        <S.StepContainer>
          <S.TickIcon src={tickIcon} />
          <S.Step>{step}</S.Step>
        </S.StepContainer>
      ))}
      {link && (
        <S.LearnMore>
          {learnMoreTitle}{' '}
          {isInsideLink ? (
            <img src={linArrow} alt="" />
          ) : (
            <FontIcon name="link-ext" fontSize={15} style={{ marginLeft: 5 }} />
          )}
        </S.LearnMore>
      )}
    </S.Card>
  </S.Link>
);

Card.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  extra: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.node).isRequired,
  learnMoreTitle: PropTypes.string,
  isInsideLink: PropTypes.bool,
};

Card.defaultProps = {
  link: undefined,
  extra: undefined,
  learnMoreTitle: 'LEARN MORE',
  isInsideLink: true,
};

const cards = [
  {
    icon: bookIcon,
    title: 'Masterclass',
    description: <>1-2 hours</>,
    steps: [
      <>
        <b>GenAI wins</b>
        <br />
        <span>Identify the workflows that can offer you the bigger GenAI “wins”.</span>
      </>,
      <>
        <b>Prompt engineering</b>
        <br />
        <span>We work with you to engineer your mega- prompts per department & per role.</span>
      </>,
      <>
        <b>Prompt library</b> <i>(Static)</i>
        <br />
        <span>We build your first library on Notion or your company&apos;s knowledge base.</span>
      </>,
    ],
  },
  {
    link: 'https://www.100mentors.com/AI-bootcamp/marketing/',
    icon: booksIcon,
    title: 'Bootcamp',
    description: <>2-10 hours</>,
    extra: 'All the AI Masterclass benefits and',
    steps: [
      <>
        <b>Prompt library</b> <i>(Dynamic)</i>
        <br />
        <span>We help and support you by building and maintaining an ever evolving prompt library.</span>
      </>,
      <>
        <b>Prompt support</b>
        <br />
        <span>A GenAI human expert works with you to optimize and customize your dynamic library</span>
      </>,
      <>
        <b>Prompt auditing</b>
        <br />
        <span>We train and certify an employee per department to act as the prompt “Librarian”.</span>
      </>,
    ],
    learnMoreTitle: 'SEE EXAMPLE',
  },
  {
    isInsideLink: false,
    link: 'https://genaisummitseeurope.com/',
    icon: summitIcon,
    title: 'GenAI Summit',
    description: (
      <>
        Part 1: June 15, 2023,
        <br />
        Part 2: February 29, 2024
      </>
    ),
    steps: [
      <>
        Join us and celebrate the best practices developed through our GenAI <b>Masterclasses</b> and <b>Bootcamps</b>.
      </>,
      <>
        Enter the next era of <b>digital transformation</b> by participating in panels held with top leaders, talks with
        passionate innovators, and meeting all interested in discovering the newest GenAI trends.
      </>,
      <>Position yourself as a GenAI thought leader and inspire your existing and future customers and employees.</>,
    ],
  },
];

export const LearnByStudyingSection = () => (
  <S.LearnByStudyingSection id="learning-by-studying">
    <S.PageWrapper>
      <S.HeadContainer>
        <Heading highlightColor={colors.hmBrandPurple}>
          Learn by <span>studying</span>
        </Heading>
        <S.BigText>Super-charge your team&apos;s capabilities with 100mentor&apos;s guided approach.</S.BigText>
        <S.Text>
          Learn to navigate the newest AI workflows, and how to seamlessly integrate GenAI within your company&apos;s
          framework and every team member&apos;s role, using one or all three of our training offerings tailored to your
          needs.
        </S.Text>
        <S.Arrow src={arrowIcon} />
      </S.HeadContainer>
      <S.CardsContainer>{cards && cards.map(card => <Card {...card} />)}</S.CardsContainer>
      <S.SavingsWrapper>
        <S.SavingsContainer>
          <S.SavingsTitle>
            <span style={{ fontWeight: 500 }}>Automate your team&apos;s workflows and save</span> an average of{' '}
            <span style={{ color: colors.hmBrandPurple }}>96.2</span> working days | year and{' '}
            <span style={{ color: colors.hmBrandPurple }}>13,690 €</span> | employee | year *
          </S.SavingsTitle>
          <S.Disclaimer>
            * estimated savings numbers are calculated based on the average European salary of a middle level business
            associate/manager.
          </S.Disclaimer>
          <S.WalletIcon src={walletIcon} />
        </S.SavingsContainer>
      </S.SavingsWrapper>
    </S.PageWrapper>
    <S.LaptopContainer>
      <S.LaptopImage src={laptopImage} />
    </S.LaptopContainer>
  </S.LearnByStudyingSection>
);
