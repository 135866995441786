import React from 'react';
import styled from 'styled-components';
import theme, { media } from '../../styles/theme';
import Section from '../../components/Section';
import Wrapper, { PageWrapper } from '../../components/Wrapper';
import Paragraph from '../../components/Paragraph';
import Heading from '../../components/Heading';

import VideoBackground from '../shared/VideoBackground';

const TempSection = styled(Section)`
  position: relative;
  background-color: #000000a0;
  box-sizing: border-box;
  height: 670px;
`;

/* Display elements on top of of video */
const RelativePageWrapper = styled(PageWrapper)`
  position: relative;
  z-index: ${theme.zIndex.foregroundBottom};
  margin-top: 80px;
  padding: 20px 20px;

  ${media.sm`
    padding: 80px 20px 0px 20px;
  `};
`;

const Text = styled.div`
  margin-bottom: 12px;
  span {
    font-style: italic;
  }
`;

const HeadSection = () => (
  <TempSection overflow="hidden">
    <VideoBackground />
    <RelativePageWrapper>
      <Wrapper maxWidth="675px" mx="auto">
        <Heading color="white" fontWeight={theme.fontWeights.bold} textAlign="center" mb="10px">
          Join our mission!
        </Heading>
        <Paragraph color="white" fontWeight={theme.fontWeights.light} lineHeight="1.39" textAlign="center">
          <Text>
            We train people in the skill of the decade, mastering the art of asking better questions and building better
            prompts. We certify humans to unlock the full potential of role-specific workflows to optimize both time
            savings and high-quality outputs. Building a product that democratizes knowledge, defines how we see our
            organizational culture.
          </Text>
          <Text>
            You will join a team where active listening and intellectual humility are at the forefront along with taking
            ownership and making data-driven decisions.
          </Text>
        </Paragraph>
      </Wrapper>
    </RelativePageWrapper>
  </TempSection>
);

export default HeadSection;
