import styled from 'styled-components';
import { width, height, maxWidth, maxHeight, space } from 'styled-system';

export const Image = styled.img`
  ${width};
  ${height};
  ${maxWidth};
  ${maxHeight};
  ${space};
`;

Image.propTypes = {
  ...width.propTypes,
  ...height.propTypes,
  ...maxWidth.propTypes,
  ...space.propTypes,
  ...maxHeight.propTypes,
};
