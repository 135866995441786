import styled from 'styled-components';

export default {
  Container: styled.div`
    font-size: 14px;
    line-height: 1.42;
  `,
  Title: styled.h1`
    font-family: 'Lato';
    font-weight: normal;
    font-size: 32px;
  `,
  Updated: styled.div`
    margin-bottom: 15px;
  `,
  Markdown: styled.div`
    ol {
      list-style-type: none;
      counter-reset: item;
      margin: 0;
      padding: 0;
    }

    ol > li {
      display: table;
      counter-increment: item;
      margin-bottom: 0.6em;
    }

    ol > li:before {
      content: counters(item, '.') '. ';
      display: table-cell;
      padding-right: 0.6em;
    }

    li ol > li {
      margin: 0;
    }

    li ol > li:before {
      content: counters(item, '.') ' ';
    }

    table,
    th,
    td {
      border: 1px solid black;
      border-spacing: 0px;
      border-collapse: collapse;
      padding: 6px;
    }
    table {
      margin-bottom: 10px;
    }
    th {
      white-space: nowrap;
      padding-right: 20px;
    }
  `,
};
