import React from 'react';
import Heading from 'src/page-components/shared/Heading';
import certification1Image from 'src/assets/images/home/certification-1.png';
import certification2Image from 'src/assets/images/home/certification-2.png';
import dartImage from 'src/assets/images/home/certification-dart.svg';
import dartLineImage from 'src/assets/images/home/certification-dart-line.svg';

import S from './CertificationSection.Styled';

export const CertificationSection = () => (
  <S.Section backgroundColor="#fafafa">
    <S.PageWrapper>
      <S.Content>
        <S.DartImage>
          <img src={dartImage} alt="Dart" />
        </S.DartImage>
        <S.DartLineImage>
          <img src={dartLineImage} alt="Dart line" />
        </S.DartLineImage>
        <S.HeadContainer>
          <Heading>Certification</Heading>
          <S.BigText>
            “...[GenAI] transformed the skill to ask better questions into the skill of the decade. 100mentors is the
            only tool available out there certifying our employees for this skill and offering our management team the
            visibility to realise its direct Return on Investment.”
          </S.BigText>
          <S.SmallText>- Thomas S. Big4 Senior Manager</S.SmallText>
        </S.HeadContainer>
        <S.Body>
          <S.Image>
            <img src={certification1Image} alt="Certification template" />
          </S.Image>
          <S.Image>
            <img src={certification2Image} alt="Certification analyze" />
          </S.Image>
        </S.Body>
      </S.Content>
    </S.PageWrapper>
  </S.Section>
);
