import styled from 'styled-components';
import theme from '../../styles/theme';

export default {
  EmailLink: styled.a`
    &,
    &:visited {
      color: ${theme.colors.hmBrandPurple};
    }
  `,
  NoJobsTitle: styled.div`
    margin: 36px 0px 6px 0px;
  `,
  NoJobsSubTitle: styled.div`
    font-size: 16px;
  `,
};
