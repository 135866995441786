import styled from 'styled-components';
import Section from 'src/components/Section';
import { media } from 'src/styles/theme';

export default {
  TrustedBySection: styled(Section)``,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  LogosContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    align-items: center;

    div :nth-child(3),
    div :nth-child(4) {
      width: 106px;
      ${media.sm`
        width: 180px;
      `};
    }
  `,
  Logo: styled.div`
    width: 53px;
    padding: 15px;

    ${media.sm`
      width: 90px;
    `};
  `,
  TinyHeading: styled.div`
    font-size: 13px;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
  `,
};
