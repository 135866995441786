import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { color, space } from 'styled-system';
import { darken } from 'polished';
import { colors } from '../../styles/theme';

const InnerLink = styled(GatsbyLink)`
  text-decoration: none;

  &:visited {
    ${color};
  }

  &:hover {
    color: ${props => darken(0.2, colors[props.color])};
  }

  ${color};
  ${space};
`;

InnerLink.propTypes = {
  ...color.propTypes,
  ...space.propTypes,
};

InnerLink.defaultProps = {
  color: 'hmBlack',
};

export default InnerLink;
