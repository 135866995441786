import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fontSize, color, space } from 'styled-system';
import '../../assets/fontello/css/fontello.css';

const FontIconStyled = styled.span`
  ${space};
  ${fontSize};
  ${color};
`;

const FontIcon = ({ className, name, ...restProps }) => (
  <FontIconStyled className={`${className} icon-${name}`} {...restProps} />
);

FontIcon.propTypes = {
  name: PropTypes.string.isRequired,
  ...space.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
};

export default FontIcon;
