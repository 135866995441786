import React from 'react';
import { PageWrapper } from 'src/components/Wrapper';
import Grid from 'src/components/Grid';
import Section from 'src/components/Section';
import Heading from 'src/components/Heading';
import Paragraph from 'src/components/Paragraph';
import theme from 'src/styles/theme';

import theProblemImage from 'src/assets/images/about/theProblem.svg';
import missionStatementImage from 'src/assets/images/about/missionStatement.svg';

import OurWhyCard from './OurWhyCard';

const OurWhySection = () => (
  <Section bg="paleGrey">
    <PageWrapper>
      <Heading textAlign="center" fontWeight={theme.fontWeights.bold} mb="12px">
        Finding our Why
      </Heading>
      <Paragraph textAlign="center" lineHeight="1.39">
        We built what we wanted to have when we were called to find our answers to the universal questions:
      </Paragraph>
      <Paragraph textAlign="center" fontWeight={500} lineHeight="1.39" mb="30px">
        ”What do I want to be when I grow up?“ <br />
        ”What am I going to be 5 years ahead from now? In 10 years?“ <br />
        ”What is the plan to pursue and how am I going to achieve its milestones?“ <br />
      </Paragraph>
      <Grid
        justifyContent="space-between"
        gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
        gridRowGap="20px"
        gridColumnGap="20px"
      >
        <OurWhyCard title="the problem" iconSrc={theProblemImage}>
          <span>We make our most critical career and academic decisions </span>
          <strong>randomly</strong>.
        </OurWhyCard>
        <OurWhyCard title="mission statement" iconSrc={missionStatementImage}>
          <span>We empower the world’s youth to </span>
          <strong>make their once in a life decisions consciously.</strong>
        </OurWhyCard>
      </Grid>
    </PageWrapper>
  </Section>
);

export default OurWhySection;
