import styled from 'styled-components';
import theme, { colors } from '../../styles/theme';

import Heading from './Heading';

const TinyHeading = styled(Heading)`
  font-size: ${theme.fontSizes.s};
  color: ${colors.hmGrey2};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`;

export default TinyHeading;
