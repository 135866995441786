import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { width as styledWidth } from 'styled-system';
import { transparentize } from 'polished';
import axios from 'axios';
import _ from 'lodash';

import Input from './Input';
import FontIcon from '../FontIcon';
import theme from '../../styles/theme';

const CustomInput = styled(Input)`
  border-color: ${({ isInputValid }) => (isInputValid ? theme.colors.hmGreen : '')};

  &:focus {
    border-color: ${({ isInputValid }) => (isInputValid ? theme.colors.hmGreen : '')};
  }
`;

const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${theme.colors.hmGrey1};
  display: inline-block;
  margin-left: -30px;
  text-align: center;
`;

const InputFontIcon = styled(FontIcon)`
  margin-left: -1px;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  min-height: 40px;

  input {
    padding-right: 35px;
    color: ${theme.colors.hmGrey3};
  }
  span {
    color: ${({ isInputValid }) => (isInputValid ? theme.colors.hmGreen : '')};
  }

  div {
    background-color: ${({ isInputValid, disabled }) => {
      if (disabled) return transparentize(0.8, theme.colors.hmGrey1);
      if (isInputValid) return transparentize(0.8, theme.colors.hmGreen);
      return '';
    }};
  }
`;

const Subscribed = styled.div`
  min-height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: ${theme.colors.hmGreen};
`;

const AlreadySubscribed = styled.div`
  min-height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: ${theme.colors.hmGrey3};
`;

const NotSubscribed = styled.div`
  min-height: 40px;
  font-size: 14px;
  color: ${theme.colors.hmGrey3};
`;

const RetryLink = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  color: ${theme.colors.hmBlue};
  font-size: 14px;
  font-weight: bold;
`;

const SubscribeInput = ({ className, iconName, width, placeholder, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [subscriptionState, setSubscriptionState] = useState('NO_SUBSCRIBED');
  const [isInputValid, setIsInputValid] = useState(false);

  useEffect(() => {
    const isEmail = email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    setIsInputValid(isEmail);

    return () => null;
  }, [email]);

  const submit = async () => {
    if (!isInputValid || isLoading) return;

    setIsLoading(true);
    try {
      const response = await axios({
        url: 'https://blog.100mentors.com/',
        method: 'POST',
        data: `email=${email}&action=subscribe&source=https://www.100mentors.com/&sub-type=widget&redirect_fragment=485&jetpack_subscriptions_widget=Subscribe`,
      });
      setSubscriptionState('SUBSCRIBED');

      const getResponseUrl = _.property('request.responseURL');
      const responseUrl = getResponseUrl(response);
      const [, status] = responseUrl.split('subscribe=');

      if (status === 'already') {
        setSubscriptionState('ALREADY_SUBSCRUBED');
      }
    } catch (error) {
      setSubscriptionState('SUBSCRIPTION_FAILED');
    }

    setIsLoading(false);
  };

  if (subscriptionState === 'SUBSCRIBED') {
    return <Subscribed>Check your email to confirm your subscription!</Subscribed>;
  }

  if (subscriptionState === 'ALREADY_SUBSCRUBED') {
    return <AlreadySubscribed>You are already subscribed</AlreadySubscribed>;
  }

  if (subscriptionState === 'SUBSCRIPTION_FAILED') {
    return (
      <NotSubscribed>
        Oops! something went wrong. Please{' '}
        <RetryLink onClick={() => setSubscriptionState('NO_SUBSCRIBED')}>retry</RetryLink>.
      </NotSubscribed>
    );
  }

  return (
    <InputWrapper className={className} isInputValid={isInputValid} disabled={isLoading}>
      <CustomInput
        disabled={isLoading}
        value={email}
        width={width}
        placeholder={placeholder}
        type={type}
        onChange={({ target: { value } }) => setEmail(value)}
        isInputValid={isInputValid}
        onKeyPress={({ key }) => {
          if (key === 'Enter') {
            submit();
          }
        }}
      />
      <IconWrapper>
        <InputFontIcon
          disabled={isLoading}
          name={iconName}
          fontSize="12px"
          color={theme.colors.hmGrey3}
          onClick={submit}
        />
      </IconWrapper>
    </InputWrapper>
  );
};

SubscribeInput.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  ...styledWidth.propTypes,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

SubscribeInput.defaultProps = {
  className: '',
  placeholder: null,
  type: 'text',
};

export default SubscribeInput;
