import styled from 'styled-components';
import { media } from 'src/styles/theme';

const Heading = styled.h1`
  font-family: Lato;
  font-size: 36px;
  text-align: left;
  font-weight: 700;
  margin: 15px 0px;

  ${media.sm`
      margin: 30px 0px;
  `}

  ${media.md`
      font-size: 65px;
  `};

  span {
    color: ${({ highlightColor }) => highlightColor};
  }
`;

export default Heading;
