import styled from 'styled-components';
import Section from 'src/components/Section';
import { PageWrapper } from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import { media, font, colors } from 'src/styles/theme';

export default {
  MentorsSpotlightSection: styled(Section)`
    background: ${({ bgColor }) => colors[bgColor]};
  `,
  PageWrapper: styled(PageWrapper)`
    padding: 80px 0;
    text-align: center;
  `,
  Heading: styled(Heading)`
    padding: 0 20px;
    margin-bottom: 10px;
  `,
  Description: styled.div`
    font-weight: ${font.fontWeights.light};
    max-width: 750px;
    padding: 0 20px;
    margin: 0 auto 40px auto;
  `,
  Title: styled.div`
    font-size: ${font.headingFontSizes.xs};
    font-weight: ${font.fontWeights.bold};
    color: ${colors.hmGrey3};
    text-transform: uppercase;
    margin-bottom: 20px;
  `,
  Mentors: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    ${media.md`
      flex-direction: row;
      justify-content: center;
      width: 100%;
    `};
  `,
  Spotlight: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    max-width: 340px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    ${media.md`
      &:not(:last-child) {
        margin-right: 40px;
        margin-bottom: 0;
      }
    `};
  `,
  SpotlightLink: styled.a`
    text-decoration: none;
  `,
  PhotoContainer: styled.div`
    position: relative;
  `,
  MentorPhoto: styled.img`
    display: block;
    width: 100%;
  `,
  SpotlightType: styled.div`
    position: absolute;
    top: 15px;
    left: 15px;
  `,
  PlayImage: styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 44px;
    height: 50px;
  `,
  SpotlightFooter: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.white};
    width: 100%;
    border: 1px solid ${colors.hmGrey1};
    box-sizing: border-box;
    padding: 13px 0px;
  `,
  SpotlightMentorName: styled.div`
    font-size: ${font.headingFontSizes.xl};
    font-weight: 500;
    color: #000000;
  `,
};
