import styled from 'styled-components';
import Section from 'src/components/Section';
import Wrapper from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import { StatCard } from 'src/components/StatCard';
import { media, breakPoints, colors, font } from 'src/styles/theme';

export default {
  ImpactReportSection: styled(Section)`
    background: ${({ bgColor }) => colors[bgColor]};
  `,
  PageWrapper: styled(Wrapper)`
    margin-left: auto;
    margin-right: auto;
    padding: 80px 10px 50px 10px;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  SmallHeading: styled(Heading)`
    font-size: ${font.headingFontSizes.xs};
    font-weight: ${font.fontWeights.bold};
    color: ${colors.hmGrey3};
    margin-bottom: 20px;
  `,
  Title: styled(Heading)`
    color: ${colors.hmBlack};
    font-size: ${font.fontSizes.xxxl};
    font-weight: ${font.fontWeights.normal};
    text-align: center;
    max-width: 700px;
  `,
  StatsContainer: styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin: 40px 10px 0 10px;
    max-width: ${({ maxWidth }) => maxWidth || '750px'};
    width: 100%;

    @media only screen and (min-width: ${breakPoints.md.min}) {
      flex-direction: row;
      justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
    }
  `,
  StatCard: styled(StatCard)`
    margin-bottom: 30px;
    ${media.sm`
      margin-left: 5px;
      margin-right: 5px;
    `};
  `,
};
