import styled from 'styled-components';
import { fontWeight, fontSize, color } from 'styled-system';
import { transparentize } from 'polished';
import theme, { colors } from '../../styles/theme';

const TextButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  font-size: ${theme.fontSizes.m};
  padding: 10px;
  cursor: pointer;

  ${fontWeight};
  ${color};
  ${fontSize};

  &:hover {
    color: ${props => transparentize(0.3, colors[props.color])};
  }

  &:active {
    color: ${props => colors[props.color]};
  }
`;

TextButton.propTypes = {
  ...fontWeight.propTypes,
  ...color.propTypes,
  ...fontSize.propTypes,
};

TextButton.defaultProps = {
  color: 'hmBlack',
};

export default TextButton;
