import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from 'src/components/Layout';
import MainHelmet from 'src/components/MainHelmet';
import Link from 'src/components/Link';
import {
  HeroBrown,
  ImpactReportTemplate,
  MentorsSpotlightTemplate,
  CommunityTemplate,
  LearningCommunitiesBrown,
  MentorCommunityBrown,
  TestimonialsTemplate,
  FooterTemplate,
  HowItWorksBrown,
} from 'src/page-components/showcase';
import metaImage from 'src/assets/images/showcase/brown/thumbnail-brown.png';
import topicsStatIcon from 'src/assets/images/showcase/topics-icon-purple.svg';
import questionsStatIcon from 'src/assets/images/showcase/questions-icon-yellow.svg';
import answersStatIcon from 'src/assets/images/showcase/answers-icon-green.svg';
import visitstatIcon from 'src/assets/images/showcase/visits-icon-blue.svg';

import girlAvatar from 'src/assets/images/girl-avatar.svg';
import boyAvatar from 'src/assets/images/boy-avatar.png';

const spotlightDescription =
  'Catch a glimpse of how Questions and Answers are depicted on the 100mentors application. Click on the photos to see the questions and the video answers on our social media.';

const statCards = [
  { color: 'hmBrandPurple', image: topicsStatIcon, number: 43, text: 'topics created', maxWidth: '300px' },
  {
    color: 'hmBrandYellow',
    image: questionsStatIcon,
    number: 206,
    text: 'questions asked by students',
    maxWidth: '300px',
  },
  { color: 'hmGreen', image: answersStatIcon, number: 353, text: 'answers received', maxWidth: '300px' },
  { color: 'hmBlue', image: visitstatIcon, number: 10, text: 'live visits', maxWidth: '300px' },
];

const testimonialsDescription =
  'Check out what participants think of the Community STEM program and how it helped them with their personal development.';

const testimonials = [
  {
    text: (
      <>
        My experience with the program was simple and easy to navigate, once I got the hang of it, in how the system
        works. The kids enjoyed the interactions, and I too had fun being able to assess the students and see the
        reactions to the feedback, both from the instructors as well as the students
      </>
    ),
    avatar: boyAvatar,
    name: 'Elder Marcus Hill',
    country: 'St. Augustine School, USA',
  },
  {
    text: (
      <>
        It has been an exciting and successful year we had with Brown x 100mentors collaboration. Having a 2nd-year
        experience with a broader expansion in our community STEM program is even more exciting. Together, we are
        looking forward to inspiring many Middle school students from diverse backgrounds.
      </>
    ),
    avatar: girlAvatar,
    name: 'Ola Hassan',
    country: 'Program Admin & PhD @ Brown',
  },
  {
    text: (
      <>
        We don’t do it for any kind of recognition. We do it to help. The more we reach, the better. I am most inspired
        by all this!
      </>
    ),
    avatar: girlAvatar,
    name: 'Margot Martinez Moreno',
    country: 'Mentor & Postdoctoral Research Associate @ Brown',
  },
];

const BrownPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mattiaSpotlight: file(relativePath: { eq: "showcase/brown/mattia-spotlight-brown.png" }) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      margotSpotlight: file(relativePath: { eq: "showcase/brown/margot-spotlight-brown.png" }) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sanMiguelLogo: file(relativePath: { eq: "showcase/brown/san-miguel-logo.png" }) {
        childImageSharp {
          fixed(width: 160) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      nativityMiguelLogo: file(relativePath: { eq: "showcase/brown/nativity-miguel-logo.png" }) {
        childImageSharp {
          fixed(width: 192) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      alhulaLogo: file(relativePath: { eq: "showcase/brown/alhula-logo.png" }) {
        childImageSharp {
          fixed(width: 85) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      westBroadwayLogo: file(relativePath: { eq: "showcase/brown/west-broadway-logo.png" }) {
        childImageSharp {
          fixed(width: 85) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      fregoso: file(relativePath: { eq: "showcase/brown/fregoso.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      hassan: file(relativePath: { eq: "showcase/brown/hassan.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      karambizi: file(relativePath: { eq: "showcase/brown/karambizi.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      martinez: file(relativePath: { eq: "showcase/brown/martinez.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      suita: file(relativePath: { eq: "showcase/brown/suita.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      tapinos: file(relativePath: { eq: "showcase/brown/tapinos.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      toruner: file(relativePath: { eq: "showcase/brown/toruner.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  const spotlights = [
    {
      type: 'Q&A',
      name: 'Mattia Pizzagalli',
      photo: data.mattiaSpotlight,
      link: 'https://www.instagram.com/p/COiUiMkp1iI/',
    },
    {
      type: 'Q&A',
      name: 'Margot Martinez Moreno',
      photo: data.margotSpotlight,
      link: 'https://www.instagram.com/p/CGXe-t6gJ7K/',
    },
  ];

  const mentors = [
    {
      id: '5b44cedeb68df97a9805fd9c',
      name: (
        <>
          Nikos
          <br />
          Tapinos MD, PhD
        </>
      ),
      photo: data.tapinos,
    },
    {
      id: '5f514fcd0b40666b381eb663',
      name: (
        <>
          Margot
          <br />
          Martinez-Moreno
        </>
      ),
      photo: data.martinez,
    },
    {
      id: '5f56813a0b40666b381ebd13',
      name: (
        <>
          Kristen
          <br />
          Fregoso
        </>
      ),
      photo: data.fregoso,
    },
    {
      id: '5f5688f00b40666b381ebd2b',
      name: (
        <>
          Ola
          <br />
          Hassan
        </>
      ),
      photo: data.hassan,
    },
    {
      id: '5f4fce690b40666b381eb3df',
      name: (
        <>
          David
          <br />
          Karambizi
        </>
      ),
      photo: data.karambizi,
    },
    {
      id: '5f1218d567c1ed38e0b32c68',
      name: (
        <>
          Yusuke
          <br />
          Suita
        </>
      ),
      photo: data.suita,
    },
    {
      id: '5f8517be987e4263e90bfb46',
      name: (
        <>
          Deniz
          <br />
          Toruner
        </>
      ),
      photo: data.toruner,
    },
  ];

  return (
    <Layout noCookies>
      <MainHelmet
        addonTitle="Community STEM @ Brown University x 100mentors"
        meta={{
          image: metaImage,
          description: 'Bridging communities to impact STEM access and learning anywhere, anytime',
        }}
      />
      <HeroBrown schools={7} students={650} mentors={51} />
      <HowItWorksBrown />
      <MentorsSpotlightTemplate
        bgColor="paleGrey"
        heading="Mentors in the Spotlight"
        description={spotlightDescription}
        spotlights={spotlights}
      />
      <ImpactReportTemplate
        title="What has the Brown University x 100mentors collaboration achieved in 2020-2023?"
        justifyContent="space-around"
        statCards={statCards}
      />
      <CommunityTemplate
        bgColor="paleGrey"
        title="Learning Communities"
        smallTitle="Schools Participating"
        content={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <LearningCommunitiesBrown
            logos={[data.sanMiguelLogo, data.nativityMiguelLogo, data.alhulaLogo, data.westBroadwayLogo]}
            communities={[{ name: 'Potter Burns ', type: 'Elementary School' }]}
          />
        }
        description="Interested in bringing this program to your school? Register your interest here!"
        registerLink="https://form.typeform.com/to/teKhINvF"
      />
      <CommunityTemplate
        title="Mentor Community"
        smallTitle="Mentors Participating"
        content={<MentorCommunityBrown mentors={mentors} />}
        description="Get started with your mentoring journey by filling in this form."
        registerLink="https://form.typeform.com/to/IWFw5cNB"
      />
      <TestimonialsTemplate
        bgColor="paleGrey"
        title="Community Stories"
        description={testimonialsDescription}
        testimonials={testimonials}
      />
      <FooterTemplate>
        <div style={{ maxWidth: '680px', fontSize: '18px', fontWeight: '300', lineHeight: '25px' }}>
          <strong>Community STEM Project</strong> is funded by the <strong>Tapinos Lab</strong>, a Molecular
          Neuroscience &amp; Neuro-Oncology Laboratory at Brown University. To find out more about their team or explore
          how to support the project, check out the{' '}
          <Link color="hmBlue" href="http://www.tapinoslab.com/" target="_blank" rel="noreferrer">
            Tapinos Lab website
          </Link>
          .
        </div>
      </FooterTemplate>
    </Layout>
  );
};

export default BrownPage;
