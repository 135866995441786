import styled from 'styled-components';
import { darken } from 'polished';
import FontIcon from 'src/components/FontIcon';
import { colors } from 'src/styles/theme';

export default {
  SocialList: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `,
  SocialListItem: styled.div`
    display: flex;
    align-items: center;
  `,
  SocialIcon: styled(FontIcon)`
    font-size: 33px;
    color: ${colors.hmGrey3};

    &:hover {
      color: ${colors.hmBrandPurple};
    }

    &:active {
      color: ${darken(0.2, colors.hmBrandPurple)};
    }

    cursor: pointer;
    transition: all 150ms ease;
  `,
};
