import styled from 'styled-components';

export default {
  CommunitiesWrapper: styled.div`
    margin: 0 auto 30px auto;
  `,
  Communities: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div {
      margin: 20px 0;
    }

    @media only screen and (min-width: 751px) {
      flex-direction: row;
      & > div:not(:last-child) {
        margin-right: 60px;
      }
    }
  `,
};
