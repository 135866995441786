import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Wrapper, { FlexWrapper } from '../../components/Wrapper';
import Paragraph from '../../components/Paragraph';
import { Image } from '../../components/Image';
import theme, { colors } from '../../styles/theme';

const CardStyled = styled.div`
  border: 1px solid ${colors.hmGrey1};
  padding: 25px 20px 25px 30px;
  box-sizing: border-box;
`;

const SmallHeading = styled.h3`
  font-size: ${theme.fontSizes.s};
  font-weight: ${theme.fontWeights.bold};
  color: ${colors.hmBrandPurple};
  text-transform: uppercase;
`;

const OurWhyCard = ({ iconSrc, title, children }) => (
  <CardStyled>
    <FlexWrapper>
      <Wrapper mr="20px">
        <Image src={iconSrc} height="103px" />
      </Wrapper>
      <Wrapper>
        <SmallHeading>{title}</SmallHeading>
        <Paragraph>{children}</Paragraph>
      </Wrapper>
    </FlexWrapper>
  </CardStyled>
);

OurWhyCard.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default OurWhyCard;
