import styled from 'styled-components';
import { width, space } from 'styled-system';
import theme, { colors } from '../../styles/theme';

const Input = styled.input`
  font-size: ${theme.fontSizes.m};
  padding: 10px;
  border: 1px solid ${colors.hmGrey2};

  &::placeholder {
    color: ${colors.hmGrey2};
  }

  &:focus {
    outline: none;
    border: 1px solid ${colors.hmBrandPurple};
  }

  ${width};
  ${space};
`;

Input.propTypes = {
  ...width.propTypes,
  ...space.propTypes,
};

export default Input;
