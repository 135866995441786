import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, space, textAlign } from 'styled-system';
import theme from '../../styles/theme';

const StyledHeading = styled.h1`
  font-family: ${theme.fonts.lato};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-size: ${({ size }) => {
    switch (size) {
      case 1:
        return theme.fontSizes.xxxl;
      case 2:
        return theme.fontSizes.xxl;
      case 3:
        return theme.fontSizes.xl;
      default:
        return theme.fontSizes.xxxl;
    }
  }};
  margin: 0;
  ${color};
  ${space};
  ${textAlign};
`;

const Heading = ({ className, size, children, ...restProps }) => {
  return (
    <StyledHeading as={`h${size}`} size={size} className={className} {...restProps}>
      {children}
    </StyledHeading>
  );
};

Heading.propTypes = {
  size: PropTypes.number,
  ...color.propTypes,
  ...space.propTypes,
  ...textAlign.propTypes,
};

Heading.defaultProps = {
  size: 1,
  color: theme.colors.hmBlack,
};

export default Heading;
