import styled, { css } from 'styled-components';

const switchOrientation = ({ orientation }) => {
  switch (orientation) {
    case 'vertical':
      return css`
        li {
          text-align: left;
          padding: 5px 0;
        }
      `;
    case 'horizontal':
    default:
      return css`
        li {
          display: inline-block;
          vertical-align: center;
          padding: 0 20px;
        }
      `;
  }
};

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  color: white;

  ${switchOrientation};
`;

export default Menu;
