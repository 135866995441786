import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { PageWrapper } from 'src/components/Wrapper';
import { FluidImage } from 'src/components/Image';
import S from './TrustedBySection.Styled';

export const TrustedBySection = () => {
  const data = useStaticQuery(graphql`
    query {
      eyLogo: file(relativePath: { eq: "home/ey-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ptiLogo: file(relativePath: { eq: "home/pti-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mcKinseyLogo: file(relativePath: { eq: "home/mckinsey-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      brownLogo: file(relativePath: { eq: "home/brown-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <S.TrustedBySection>
      <PageWrapper>
        <S.Content>
          <div style={{ width: '100%' }}>
            <S.TinyHeading>Trusted by 50 of the world&apos;s top employers & organizations</S.TinyHeading>
            <S.LogosContainer>
              <S.Logo>
                <FluidImage image={data.eyLogo} alt="Ey Logo" />
              </S.Logo>
              <S.Logo>
                <FluidImage image={data.ptiLogo} alt="Prince's Trust Logo" />
              </S.Logo>
              <S.Logo>
                <FluidImage image={data.mcKinseyLogo} alt="mcKinsey Logo" />
              </S.Logo>
              <S.Logo>
                <FluidImage image={data.brownLogo} alt="Brown Logo" />
              </S.Logo>
            </S.LogosContainer>
          </div>
        </S.Content>
      </PageWrapper>
    </S.TrustedBySection>
  );
};
