import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../components/Grid';
import { PageWrapper } from '../../components/Wrapper';
import Section from '../../components/Section';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import theme from '../../styles/theme';
import EmployeeBox from './EmployeeBox';

import Employees from './_employees';

const MeetTheTeamSection = ({ data }) => (
  <Section>
    <PageWrapper>
      <Heading textAlign="center" lineHeight="1.06" mb="12px">
        Meet the team
      </Heading>
      <Paragraph textAlign="center" lineHeight="1.39" fontWeight={theme.fontWeights.light} mb="50px">
        We are passionate about building a product that empowers students to become the architects of their own future,
        by developing their critical thinking, international orientation and intellectual humility.
      </Paragraph>
      <Grid
        gridTemplateColumns={['1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']}
        justifyContent="center"
        justifyItems="center"
        gridRowGap="70px"
      >
        {Employees.list.map(({ photo, ...restValues }) => (
          <EmployeeBox
            key={restValues.position + restValues.linkedIn}
            photo={data[photo].childImageSharp.fluid}
            {...restValues}
          />
        ))}
      </Grid>
    </PageWrapper>
  </Section>
);

MeetTheTeamSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MeetTheTeamSection;
