import styled from 'styled-components';
import { top, right, bottom, left } from 'styled-system';
import Wrapper from './Wrapper';

const AbsoluteWrapper = styled(Wrapper)`
  position: absolute;
  ${top};
  ${left};
  ${right};
  ${bottom};
`;

AbsoluteWrapper.propTypes = {
  ...top.propTypes,
  ...left.propTypes,
  ...right.propTypes,
  ...bottom.propTypes,
};

export default AbsoluteWrapper;
