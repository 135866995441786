import React from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/Button';

import S from './template.Community.Styled';

export const CommunityTemplate = ({ bgColor, title, smallTitle, content, description, registerLink }) => (
  <S.CommunityTemplate bgColor={bgColor}>
    <S.PageWrapper>
      <S.Heading>{title}</S.Heading>
      <S.SmallHeading>{smallTitle}</S.SmallHeading>
      {content}
      <S.Description>{description}</S.Description>
      <Button href={registerLink} color="hmBrandPurple" target="_blank">
        register
      </Button>
    </S.PageWrapper>
  </S.CommunityTemplate>
);

CommunityTemplate.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  smallTitle: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  registerLink: PropTypes.string.isRequired,
};

CommunityTemplate.defaultProps = {
  bgColor: 'white',
};
