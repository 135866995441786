import styled from 'styled-components';
import Section from 'src/components/Section';
import Logo from 'src/components/Logo';
import { PageWrapper } from 'src/components/Wrapper';
import Menu from 'src/components/Menu';
import { SubscribeInput } from 'src/components/Input';
import Link, { InnerLink } from 'src/components/Link';
import media from 'src/styles/theme/media';
import theme, { colors } from 'src/styles/theme';
import SocialItems from './SocialItems';

export default {
  Section: styled(Section)`
    position: relative;
  `,
  LogoWrapper: styled(PageWrapper)`
    position: relative;
    padding: 0px 20px;
  `,
  Logo: styled(Logo)`
    display: none;
    position: absolute;
    top: -40px;
    width: 77px;
    border: 1px solid white;

    ${media.sm`
        display:block;
    `};
  `,
  PageWrapper: styled(PageWrapper)`
    ${media.sm`
	    padding: 100px 20px 85px 20px;
    `};
  `,
  Grid: styled.div`
    display: grid;
    justify-content: space-between;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 30px;
    grid-template-areas:
      'footerCompany footerCompany footerCompany footerCompany'
      'footerContact footerContact footerContact footerContact'
      'footerDownloadApp footerDownloadApp footerDownloadApp footerDownloadApp';

    ${media.sm`
      grid-template-areas:
      'footerCompany footerCompany footerCompany footerCompany'
      'footerContact footerContact footerDownloadApp footerDownloadApp';
      grid-column-gap: 10px;
    `};

    ${media.md`
	    grid-template-columns: 140px 140px 250px 200px;
        grid-template-areas: 'footerCompany footerContact footerDownloadApp';
    `};
  `,
  GridCompanyArea: styled.div`
    grid-area: footerCompany;
  `,
  GridContactArea: styled.div`
    grid-area: footerContact;
    justify-self: left;
  `,
  GridDownloadAppArea: styled.div`
    grid-area: footerDownloadApp;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${media.sm`
      align-items: flex-end;
    `};
  `,
  AppStoreItem: styled.div`
    width: 165px;
    margin-left: -10px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:nth-child(2) {
      margin-top: 15px;
    }
  `,
  AppStoreLink: styled.a`
    color: ${colors.hmGrey3};
    &:visited {
      color: ${colors.hmGrey3};
    }

    &:hover {
      color: ${colors.hmBrandPurple};
    }

    transition: all 150ms ease;
  `,

  AreaTitle: styled.div`
    font-family: ${theme.fonts.lato};
    font-size: ${theme.fontSizes.m};
    letter-spacing: 0.4px;
    color: ${colors.hmBlack};
    text-transform: uppercase;
    white-space: nowrap;
  `,
  InnerLink: styled(InnerLink)`
    font-size: ${theme.fontSizes.l};

    :hover {
      color: ${colors.hmBrandPurple};
    }
  `,
  Link: styled(Link)`
    font-size: ${theme.fontSizes.l};

    :hover {
      color: ${colors.hmBrandPurple};
    }
  `,
  ContactContent: styled.p`
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    max-width: 315px;

    a {
      color: ${colors.hmGrey3};
      transition: all 0.25s ease;
      text-decoration: none;
    }
    a:hover {
      color: ${colors.hmBrandPurple};
    }
    a:visited {
      color: ${colors.hmBrandPurple};
    }
  `,
  Menu: styled(Menu)`
    margin: 10px 0px 0px 0px;
  `,
  SocialItems: styled(SocialItems)`
    margin-top: 15px;
    margin-left: -5px;
  `,
  SubscribeHeading: styled.div`
    font-size: ${theme.fontSizes.s};
    color: ${colors.hmGrey2};
    font-weight: ${theme.fontWeights.bold};
    text-transform: uppercase;
    margin: 14px 0px 5px 0px;
  `,
  SubscribeInput: styled(SubscribeInput)`
    input {
      width: 175px;
    }
  `,
  ReCaptchaInfo: styled.div`
    font-size: ${theme.fontSizes.s};
    color: ${colors.hmGrey3};
    text-align: right;
    margin: 30px 0 5px 0;
  `,
  HorizontalLine: styled.div`
    height: 2px;
    background: ${colors.hmGrey1};
    max-width: 100%;
    margin: ${({ hasRecaptcha }) => (hasRecaptcha ? '0 auto 30px auto' : '30px auto')};
  `,
  CopyrightContainer: styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    font-size: 16px;
    flex-direction: column;
    align-items: flex-start;
    ${media.sm`
      flex-direction: row;
      align-items: center;
    `};
  `,
  Copyright: styled.div`
    margin-bottom: 10px;
    ${media.sm`
      margin-bottom: 0;
    `};
  `,
  TermsContainer: styled.div`
    display: flex;
    flex-direction: column;
    color: ${colors.hmGrey3};
    margin-bottom: 10px;
    ${media.sm`
      flex-direction: row;
      margin-bottom: 0;
    `};

    a {
      padding-right: 20px;
      line-height: 28px;
    }
  `,
  SponsorContainer: styled.div`
    display: flex;
  `,
};
