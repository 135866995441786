import styled from 'styled-components';
import { verticalAlign } from 'styled-system';
import Wrapper from './Wrapper';

const InlineWrapper = styled(Wrapper)`
  display: inline-block;
  ${verticalAlign};
`;

InlineWrapper.propTypes = {
  ...verticalAlign.propTypes,
};

export default InlineWrapper;
