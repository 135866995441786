import styled from 'styled-components';
import Img from 'gatsby-image';
import Wrapper, { PageWrapper, AbsoluteWrapper } from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import { CircleButton } from 'src/components/Button';
import { Image } from 'src/components/Image';
import theme, { font } from 'src/styles/theme';
import media from 'src/styles/theme/media';

export default {
  StoriesSection: styled.section`
    position: relative;
    overflow: hidden;
  `,
  Background: styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  `,
  PageWrapper: styled(PageWrapper)``,
  Container: styled.div`
    margin: 0px auto;
    max-width: 650px;

    ${media.md`
      max-width: 100%;
      margin: auto;
    `}
  `,
  Heading: styled(Heading)`
    font-size: 36px;
    padding-left: 24px;
    margin-bottom: 70px;
    text-align: left;
    ${media.md`
      font-size: 65px;
    `};
  `,
  CarouselWrapper: styled(Wrapper)`
    position: relative;
    width: 100%;

    .slick-slide,
    .slick-slide * {
      outline: none !important;
    }
    .slick-track {
      display: flex;
      align-items: center;
    }

    ${media.md`
      max-width: 100%;
    `};
  `,
  ButtonsWrapper: styled(AbsoluteWrapper)`
    max-width: 100%;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: ${theme.zIndex.foregroundBottom};
    pointer-events: none;
  `,
  CircleButtonLeft: styled(CircleButton)`
    transform: scaleX(-1);
    position: absolute;
    left: 10px;
    top: 50%;
    pointer-events: auto;
  `,
  CircleButtonRight: styled(CircleButton)`
    position: absolute;
    right: 10px;
    top: 50%;
    pointer-events: auto;
  `,
  TestimonialCardWrapper: styled(Wrapper)`
    box-sizing: border-box;
    padding: 20px 30px;
  `,
  TestimonialCard: styled.div`
    position: relative;
    min-height: 270px;
    max-width: 900px;
    box-sizing: border-box;
    padding: 20px 40px;
    text-align: center;
    box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin: 0 auto;

    ${media.sm`
      padding: 30px 35px;
    `};
  `,
  CompanyLogo: styled(Img)`
    width: ${({ width }) => width};
    margin: 0 auto 25px auto;
  `,
  TestimonialContainer: styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    ${media.sm`
      margin-bottom: 30px;
    `};
  `,
  Quote: styled(Image)`
    display: none !important;
    width: 30px;

    ${media.sm`
      display: block !important;
    `};
  `,
  Testimonial: styled.div`
    font-size: 16px;
    font-weight: ${font.fontWeights.light};
    padding: 0px 20px;

    ${media.sm`
      font-size: 18px;
      margin: 0 10px;
    `};
  `,
  EmployeeName: styled.div`
    font-weight: ${font.fontWeights.bold};
    font-size: 16px;
    margin-bottom: 10px;

    ${media.sm`
      font-size: 22px;
    `};
  `,
  EmployeePosition: styled.div`
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;

    ${media.sm`
      font-size: 20px;
      line-height: 30px;
    `};
  `,
};
