import styled from 'styled-components';
import Section from 'src/components/Section';
import { PageWrapper } from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import { media, font, colors } from 'src/styles/theme';

export default {
  HowItWorksSection: styled(Section)`
    background: ${({ bgColor }) => colors[bgColor]};
  `,
  PageWrapper: styled(PageWrapper)`
    padding: 80px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.sm`
      padding: 80px 20px;
    `};
  `,
  Heading: styled(Heading)`
    margin-bottom: 10px;
  `,
  Description: styled.div`
    margin: 0 auto 40px auto;
    max-width: 600px;
    font-weight: ${font.fontWeights.light};
  `,
  LineContainer: styled.div`
    display: none;
    max-width: 880px;
    width: 75%;
    margin-left: 10px;

    ${media.md`
      display: block;
    `};
  `,
  Steps: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:last-child) {
      margin-bottom: 50px;
    }

    ${media.md`
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      bottom: 125px;
      margin-bottom: -125px;
      
      & > :not(:last-child) {
        margin-bottom: 0;
      }
    `};
  `,
  Step: styled.div`
    text-align: center;

    ${media.md`
      padding: 0 15px;
    `};
  `,
  LogoContainer: styled.div`
    margin-bottom: 15px;
    ${({ margin }) => margin};
  `,
  SmallHeading: styled(Heading)`
    margin-bottom: 10px;
    font-weight: ${font.fontWeights.bold};
    font-size: ${font.headingFontSizes.s};
  `,
  SmallDescription: styled.div`
    margin: 0 auto;
    max-width: 290px;
    font-weight: ${font.fontWeights.light};
    font-size: ${font.fontSizes.l};
  `,
};
