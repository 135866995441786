import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import theme, { colors } from '../../styles/theme';
import GDPRBanner from './Layout.GDPRBanner';
import './layout.css';

export const GlobalStyle = createGlobalStyle`
  html {
    overflow-x: hidden;
  }
  
  body {
    overflow-x: hidden !important;
    margin:0;
    font-family: ${theme.fonts.roboto};
    font-size: ${theme.fontSizes.xl};
    color: ${colors.hmBlack};
    -webkit-font-smoothing: antialiased !important;
  }

  .grecaptcha-badge { 
    visibility: hidden;
  }
`;

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GDPRBanner />
      {children}
      <GlobalStyle />
    </>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
