import React from 'react';
import Layout from 'src/components/Layout';
import Footer from 'src/components/Footer';
import Nav from 'src/components/Nav';
import MainHelmet from 'src/components/MainHelmet';
import {
  HeadSection,
  TrustedBySection,
  LearnByStudyingSection,
  LearnByDoingSection,
  CertificationSection,
  StoriesSection,
} from 'src/page-components/index';
import metaImage from 'src/assets/images/home/thumbnail-home.png';

const IndexPage = () => (
  <Layout>
    <MainHelmet meta={{ image: metaImage }} />
    <Nav logoColored />
    <HeadSection />
    <TrustedBySection />
    <LearnByStudyingSection />
    <LearnByDoingSection />
    <CertificationSection />
    <StoriesSection />
    <Footer />
  </Layout>
);

export default IndexPage;
