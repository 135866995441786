import styled from 'styled-components';
import Section from 'src/components/Section';
import { PageWrapper } from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import { media, colors, font } from 'src/styles/theme';

export default {
  PopularQuestionsSection: styled(Section)`
    background: ${({ bgColor }) => colors[bgColor]};
  `,
  PageWrapper: styled(PageWrapper)`
    padding: 80px 0;
  `,
  SmallHeading: styled(Heading)`
    font-size: ${font.headingFontSizes.xs};
    font-weight: ${font.fontWeights.bold};
    color: ${colors.hmGrey3};
    margin-bottom: 20px;
    text-align: center;
  `,
  Heading: styled(Heading)`
    padding: 0 20px;
    margin-bottom: 40px;
    text-align: center;
  `,
  QuestionCard: styled.div`
    background: ${colors.white};
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.1));
    box-sizing: border-box;
    border-radius: 20px;
    text-align: center;
  `,
  QuestionContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ gradient }) => colors.gradients[gradient]};
    border-radius: ${({ bottomRadius }) => (bottomRadius ? '20px' : '20px 20px 0 0')};
    width: 300px;
    height: 300px;

    ${media.sm`
      width: 400px;
      height: 400px;
    `};
  `,
  Question: styled(Heading)`
    max-width: 270px;
    color: ${colors.white};
    font-weight: bold;
    font-size: 38px;

    ${media.sm`
      max-width: 360px;
    `};
  `,
  QuestionCardTextConatiner: styled.div`
    border-radius: 0 0 20px 20px;
    border: 1px solid ${colors.hmGrey1};
    border-top: none;
    padding: 12px 0;

    ${media.sm`
      padding: 18px 0;
    `};
  `,
};
