import React from 'react';

const stories = data => {
  return [
    {
      companyName: 'EY',
      companyLogo: data.ey.childImageSharp.fluid,
      companyLogoWidth: '85px',
      employeeName: 'Abel V. I.',
      employeePosition: 'Senior Consultant - People Consulting',
      employeeCompany: 'EY London',
      text: (
        <>
          Huge shout out to 100mentors for their sustained efforts in revolutionising the world of digital mentoring and
          having us at EY be part of it.
        </>
      ),
    },
    {
      companyName: 'Hellenic Petroleum',
      companyLogo: data.hellenicPetroleum.childImageSharp.fluid,
      companyLogoWidth: '137px',
      employeeName: 'Jenny L.',
      employeePosition: 'Innovation Engineer',
      employeeCompany: 'Hellenic Petroleum',
      text: (
        <>
          One of the few activities with long-lasting engagement for our employees...a very rewarding activity during my
          lunch break.
        </>
      ),
    },
    {
      companyName: 'Intrasoft',
      companyLogo: data.intrasoft.childImageSharp.fluid,
      companyLogoWidth: '189px',
      employeeName: 'Ariadne L.',
      employeePosition: 'HR Capacity Building Consultant Lead',
      employeeCompany: 'Intrasoft',
      text: (
        <>
          Τechnology has literally beamed me to places I never thought I could reach. If the goal is to inspire as many{' '}
          students as possible, then I find it is the best means!
        </>
      ),
    },
  ];
};

export default stories;
