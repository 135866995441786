import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import LeftQuoteImage from 'src/assets/images/LeftQuote.png';
import S from './template.Testimonials.Styled';

const TestimonialCard = ({ text, avatar, name, country }) => (
  <S.TestimonialCard>
    <S.Quote src={LeftQuoteImage} />
    <S.Testimonial>{text}</S.Testimonial>
    <S.Avatar src={avatar} />
    <S.Name>{name}</S.Name>
    <S.Country>{country}</S.Country>
  </S.TestimonialCard>
);
TestimonialCard.propTypes = {
  text: PropTypes.node.isRequired,
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

const settings = {
  infinite: true,
  autoplay: false,
  dots: false,
  draggable: true,
  arrows: false,
  easing: 'ease-in-out',
};

const TestimonialSlider = React.createRef();

export const TestimonialsTemplate = ({ bgColor, title, description, testimonials }) => {
  return (
    <S.TestimonialsSection bgColor={bgColor}>
      <S.PageWrapper>
        <S.SmallHeading>TESTIMONIALS</S.SmallHeading>
        <S.Heading>{title}</S.Heading>
        <S.Description>{description}</S.Description>
        <S.CarouselWrapper>
          <S.ButtonsWrapper>
            <S.CircleButtonLeft iconName="arrow-right-bold" onClick={() => TestimonialSlider.current.slickPrev()} />
            <S.CircleButtonRight iconName="arrow-right-bold" onClick={() => TestimonialSlider.current.slickNext()} />
          </S.ButtonsWrapper>
          <Slider ref={TestimonialSlider} {...settings}>
            {testimonials.map(testimonial => (
              <S.TestimonialCardWrapper key={testimonial.text}>
                <TestimonialCard
                  text={testimonial.text}
                  avatar={testimonial.avatar}
                  name={testimonial.name}
                  country={testimonial.country}
                />
              </S.TestimonialCardWrapper>
            ))}
          </Slider>
        </S.CarouselWrapper>
      </S.PageWrapper>
    </S.TestimonialsSection>
  );
};

TestimonialsTemplate.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  testimonials: PropTypes.array.isRequired,
};

TestimonialsTemplate.defaultProps = {
  bgColor: 'white',
};
