import styled from 'styled-components';
import {
  width,
  height,
  maxWidth,
  minWidth,
  maxHeight,
  minHeight,
  space,
  textAlign,
  gridArea,
  justifySelf,
  display,
  zIndex,
} from 'styled-system';

const Wrapper = styled.div`
  ${space};
  ${width};
  ${height};
  ${maxWidth};
  ${minWidth};
  ${minHeight};
  ${maxHeight};
  ${textAlign};
  ${gridArea};
  ${justifySelf};
  ${display};
  ${zIndex};
`;

Wrapper.propTypes = {
  ...width.propTypes,
  ...height.propTypes,
  ...minWidth.propTypes,
  ...maxWidth.propTypes,
  ...minHeight.propTypes,
  ...maxHeight.propTypes,
  ...space.propTypes,
  ...textAlign.propTypes,
  ...gridArea.propTypes,
  ...justifySelf.propTypes,
  ...display.propTypes,
  ...zIndex.propTypes,
};

export default Wrapper;
