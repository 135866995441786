import React from 'react';
import Typist from 'react-typist';
import scrollTo from 'gatsby-plugin-smoothscroll';
import GradientText from 'src/components/GradientText';
import Button from 'src/components/Button';
import headBackgroundImage from 'src/assets/images/home/head-background.png';
import learnByStudyingImage from 'src/assets/images/home/head-lbs.jpg';
import learnByDoingImage from 'src/assets/images/home/head-lbd.png';
import criticalThinkingImage from 'src/assets/images/home/head-critical-thinking.png';
import problemSolvingImage from 'src/assets/images/home/head-problem-solving.png';
import creativityImage from 'src/assets/images/home/head-creativity.png';
import lineImage from 'src/assets/images/home/head-dotted-line.png';
import S from './HeadSection.Styled';

import 'react-typist/dist/Typist.css';

export const HeadSection = () => {
  return (
    <S.HeadSection>
      <S.PageWrapper>
        <S.Content>
          <S.Background>
            <img src={headBackgroundImage} style={{ width: '100%', height: '100%' }} alt="" />
          </S.Background>
          <S.Body>
            <S.Title>
              Your people empowered <br />
              by <GradientText gradient="linear-gradient(90deg, #7450D0, #D8AAF8)">GenAI</GradientText>
            </S.Title>
            <S.Typed>
              <Typist ms={500} startDelay={1000}>
                Ask better questions. Build better prompts.
              </Typist>
            </S.Typed>
            <S.Text>
              <p>
                <strong>100mentors</strong> trains your people in the skill of the decade – mastering the art of asking
                better questions.
              </p>

              <p>
                Certify your employees to unlock the full potential of role-specific mega-prompts, optimizing both time
                savings and high-quality outputs.
              </p>
            </S.Text>
            <S.ButtonContainer>
              <Button
                href="https://meet.100mentors.com/meetings/100mentors-for-companies/demo"
                color="hmBrandPurple"
                style={{ width: '100%' }}
                target="_blank"
              >
                Book a demo
              </Button>
            </S.ButtonContainer>
          </S.Body>
          <S.Meta>
            <S.MetaIconCriticalThinking>
              <img src={criticalThinkingImage} alt="" />
            </S.MetaIconCriticalThinking>
            <S.MetaIconProblemSolving>
              <img src={problemSolvingImage} alt="" />
            </S.MetaIconProblemSolving>
            <S.MetaIconCreativity>
              <img src={creativityImage} alt="" />
            </S.MetaIconCreativity>
            <S.MetaLine>
              <img src={lineImage} alt="" />
            </S.MetaLine>
            <S.MetaItem style={{ marginRight: 20 }} onClick={() => scrollTo('#learning-by-studying')}>
              <S.MetaImageLeft>
                <img src={learnByStudyingImage} alt="Woman on laptop" />
              </S.MetaImageLeft>
              <S.MetaLabelContainer>
                <S.MetaLabel color="#794c91">
                  <div>Learn by studying</div>
                </S.MetaLabel>
              </S.MetaLabelContainer>
            </S.MetaItem>
            <S.MetaItem onClick={() => scrollTo('#learning-by-doing')}>
              <S.MetaImageRight>
                <img src={learnByDoingImage} alt="Man learning by using 100mentors application on mobile phone" />
              </S.MetaImageRight>
              <S.MetaLabelContainer>
                <S.MetaLabel color="#FDC010">
                  <div>Learn by doing</div>
                </S.MetaLabel>
              </S.MetaLabelContainer>
            </S.MetaItem>
          </S.Meta>
        </S.Content>
      </S.PageWrapper>
    </S.HeadSection>
  );
};
