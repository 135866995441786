import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';

const GradientText = styled.span`
  background-color: ${({ fallbackColor }) => fallbackColor};
  background-image: ${({ gradient }) => gradient};
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

GradientText.propTypes = {
  gradient: PropTypes.string.isRequired,
  fallbackColor: PropTypes.string,
};

GradientText.defaultProps = {
  fallbackColor: colors.hmBlack,
};

export default GradientText;
