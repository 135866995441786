import React from 'react';
import styled from 'styled-components';
import { space, width, height } from 'styled-system';
import { darken } from 'polished';
import PropTypes from 'prop-types';
import FontIcon from '../FontIcon';
import { colors } from '../../styles/theme';

const CircleButtonStyled = styled.button`
  border: none;
  background: transparent;
  outline: none;
  border-radius: 50%;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  color: ${colors.hmBrandPurple};
  background-color: white;
  text-align: center;
  -webkit-tap-highlight-color: transparent;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${darken(0.05, colors.hmBrandPurple)};
    background-color: ${darken(0.03, 'white')};
  }

  &:active {
    box-shadow: none;
  }

  ${space};
  ${width};
  ${height};
`;

const CircleButton = ({ className, iconName, fontSize, ...restProps }) => (
  <CircleButtonStyled className={className} {...restProps}>
    {iconName && <FontIcon name={iconName} fontSize={fontSize} />}
  </CircleButtonStyled>
);

CircleButton.propTypes = {
  iconName: PropTypes.string,
  fontSize: PropTypes.string,
  ...space.propTypes,
  ...width.propTypes,
  ...height.propTypes,
};

CircleButton.defaultProps = {
  iconName: null,
  fontSize: '14px',
  // eslint-disable-next-line
  width: '40px',
  // eslint-disable-next-line
  height: '40px',
};

export default CircleButton;
