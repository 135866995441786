import styled from 'styled-components';
import Section from 'src/components/Section';
import { media, colors } from 'src/styles/theme';

export default {
  LearnByDoingSection: styled(Section)``,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 450px;

    ${media.sm`
      margin-top: 80px;
    `};

    ${media.md`
      flex-direction: row;
      margin-top: 145px;
      margin-bottom: 70px;
    `};
  `,
  HeadContainer: styled.div`
    position: relative;
    width: fit-content;
    margin: 0px auto;
    max-width: 650px;

    ${media.md`
      max-width: 730px;
      margin: 0px 0px;
    `}
  `,
  BigText: styled.div`
    font-size: 16px;
    font-weight: 500;
    color: rgba(62, 62, 62, 0.8);
    margin-bottom: 10px;

    ${media.sm`
      font-size: 29px;
      margin-bottom: 25px;
    `};
  `,
  Text: styled.div`
    color: ${colors.hmGrey3};
    font-size: 14px;
    max-width: 640px;
    margin-bottom: 30px;

    ${media.sm`
      font-size: 18px;
      margin-bottom: 55px;
    `};
  `,
  ButtonContainer: styled.div`
    width: 100%;
    max-width: 250px;
    margin: 0 auto;

    ${media.md`
      max-width: 280px;
      margin: 40px 0px 0px 0px;
    `};
  `,
  Arrow: styled.img`
    display: none;
    position: absolute;
    bottom: -50px;
    right: -50px;

    @media only screen and (min-width: 960px) {
      display: block;
    }
  `,
  AppContainer: styled.div`
    position: relative;
    width: 100%;
    max-width: 320px;
    margin: 55px auto 0 auto;

    ${media.md`
      max-width: 420px;
      margin-top: 0;
    `};
  `,
  VideoCallIcon: styled.img`
    position: absolute;
    top: 15px;
    left: 140px;
    height: 411px;

    ${media.md`
      top: 15px;
      left: 185px;
      height: 481px;
    `};
  `,
  QuestionThreadIcon: styled.img`
    position: absolute;
    height: 447px;

    ${media.md`
      left: 30px;
      height: 517px;
    `};
  `,
  UpYellowIcon: styled.img`
    position: absolute;
    top: -35px;
    left: 295px;
    width: 35px;
    height: 35px;

    ${media.md`
      top: -8px;
      left: 50px;
      width: 38px;
      height: 40px;
    `};
  `,
  UpIconStripes: styled.img`
    position: absolute;
    top: -10px;
    left: 255px;
    width: 40px;
    height: 52px;

    ${media.md`
      top: 55px;
      left: 0;
      width: 48px;
      height: 62px;
    `};
  `,
};
