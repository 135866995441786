import React from 'react';
import PropTypes from 'prop-types';
import Link from 'src/components/Link';
import Styled from './SocialItems.Styled';

const socialList = {
  facebook: 'https://www.facebook.com/100mentors',
  instagram: 'https://www.instagram.com/my100mentors',
  linkedin: 'https://www.linkedin.com/company/100mentors',
};

const SocialItems = ({ className }) => (
  <Styled.SocialList className={className}>
    <Styled.SocialListItem>
      {Object.entries(socialList).map(([name, link]) => (
        <Link href={link} key={name}>
          <Styled.SocialIcon name={name} fontSize="33px" color="hmGrey3" />
        </Link>
      ))}
    </Styled.SocialListItem>
  </Styled.SocialList>
);

SocialItems.propTypes = {
  className: PropTypes.string,
};

SocialItems.defaultProps = {
  className: '',
};

export default SocialItems;
