import styled from 'styled-components';
import { color, backgroundImage, backgroundSize, backgroundPosition, space, overflow } from 'styled-system';

const Section = styled.section`
  ${color};
  ${backgroundImage}
  ${backgroundSize}
  ${backgroundPosition}
  ${space}
  ${overflow}
`;

Section.propTypes = {
  ...color.propTypes,
  ...backgroundImage.propTypes,
  ...backgroundSize.propTypes,
  ...backgroundPosition.propTypes,
  ...space.propTypes,
  ...overflow.propTypes,
};

export default Section;
