import React from 'react';

import Layout from 'src/components/Layout';
import MainHelmet from 'src/components/MainHelmet';
import Footer from 'src/components/Footer';
import CompaniesSection from 'src/page-components/shared/CompaniesSection';
import { HeroBootcamp, ModulesBootcamp, CertificationBootcamp } from 'src/page-components/bootcamp';
import { colors } from 'src/styles/theme';
import metaImage from 'src/assets/images/bootcamp/Marketing Bootcamp Metadata.png';

const MarketingPage = () => {
  return (
    <Layout noCookies>
      <MainHelmet
        addonTitle="AI Marketing Bootcamp"
        meta={{
          image: metaImage,
          description: 'Elevate your Marketing Team’s skills with our AI-Empowered Bootcamp. Save $11k/employee/yr.',
        }}
      />
      <HeroBootcamp />
      <CompaniesSection
        title="100mentors is trusted by"
        logos={[
          { key: 'ey', width: '90px', opacity: 0.6 },
          { key: 'princesTrust', width: '90px', opacity: 0.6 },
          { key: 'pwc', width: '120px', opacity: 0.6 },
          { key: 'brown', width: '180px', opacity: 0.6 },
        ]}
        backgroundColor={colors.white}
      />
      <ModulesBootcamp />
      <CertificationBootcamp />
      <Footer bg="paleGrey" hasRecaptcha />
    </Layout>
  );
};

export default MarketingPage;
