import React from 'react';
import { PageWrapper } from 'src/components/Wrapper';
import Button from 'src/components/Button';
import Heading from 'src/page-components/shared/Heading';
import { colors } from 'src/styles/theme';
import upIconYellow from 'src/assets/images/home/up-icon-yellow.svg';
import upIconStripes from 'src/assets/images/home/up-icon-stripes.svg';
import arrowIcon from 'src/assets/images/home/doing-arrow.svg';
import videoCallPhone from 'src/assets/images/home/video-call-phone.png';
import questionThreadPhone from 'src/assets/images/home/question-thread-phone.png';

import S from './LearnByDoingSection.Styled';

export const LearnByDoingSection = () => (
  <S.LearnByDoingSection id="learning-by-doing">
    <PageWrapper>
      <S.Content>
        <S.HeadContainer>
          <Heading highlightColor={colors.hmBrandYellow}>
            Learn by <span>doing</span>
          </Heading>
          <S.BigText>
            Build responsible and sustainable Human-AI synergies inside any organization using our iOS/Android App.
          </S.BigText>
          <S.Text>
            Interacting with both our AI and human mentors will help you develop the skill to ask better questions and
            prompts in the flow and context of your own work.
            <br />
            <br />
            Join our waitlist to explore our GenAI-powered App that combines artificial and human intelligence setting a
            new standard for deepening human connections.
          </S.Text>
          <S.ButtonContainer>
            <Button
              href="https://share.hsforms.com/1JY4nFhZWQ5iLAsKVYtZozg1hk0s"
              color="hmBrandPurple"
              style={{ width: '100%' }}
              target="_blank"
            >
              join the waitlist
            </Button>
          </S.ButtonContainer>
          <S.Arrow src={arrowIcon} />
        </S.HeadContainer>
        <S.AppContainer>
          <S.VideoCallIcon src={videoCallPhone} />
          <S.QuestionThreadIcon src={questionThreadPhone} />
          <S.UpYellowIcon src={upIconYellow} />
          <S.UpIconStripes src={upIconStripes} />
        </S.AppContainer>
      </S.Content>
    </PageWrapper>
  </S.LearnByDoingSection>
);
