import React from 'react';
import Layout from 'src/components/Layout';
import Footer from 'src/components/Footer';
import Nav from 'src/components/Nav';
import MainHelmet from '../components/MainHelmet';
import HeadSection from '../page-components/jobs/HeadSection';
import LifeAtMentorsSection from '../page-components/jobs/LifeAtMentorsSection';
import JobsOpeningSection from '../page-components/jobs/JobsOpeningSection';
import metaImage from '../assets/images/jobs/metaImage.png';

const Jobs = () => (
  <Layout>
    <MainHelmet addonTitle="Jobs" meta={{ image: metaImage }} />
    <Nav />
    <HeadSection />
    <LifeAtMentorsSection />
    <JobsOpeningSection />
    <Footer />
  </Layout>
);

export default Jobs;
