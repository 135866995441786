import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from 'src/components/Layout';
import Nav from 'src/components/Nav';
import MainHelmet from 'src/components/MainHelmet';
import { colors } from 'src/styles/theme';
import Section from 'src/components/Section';
import { PageWrapper } from 'src/components/Wrapper';
import MarkdownTemplate from 'src/templates/MarkdownTemplate';

const Privacy = () => {
  const data = useStaticQuery(graphql`
    query {
      privacy: markdownRemark(frontmatter: { slug: { eq: "privacy" } }) {
        html
        frontmatter {
          date(formatString: "D MMMM YYYY")
          slug
          title
        }
      }
    }
  `);

  return (
    <Layout hideFooter>
      <MainHelmet addonTitle="Privacy Policy" />
      <Section>
        <Nav background={colors.hmBrandPurple} />
        <PageWrapper p={['100px 20px !important', '120px 20px !important']} style={{ overflow: 'auto' }}>
          <MarkdownTemplate data={data.privacy} />
        </PageWrapper>
      </Section>
    </Layout>
  );
};

export default Privacy;
