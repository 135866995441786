import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import Img from 'gatsby-image';
import Wrapper, { FlexWrapper } from '../../components/Wrapper';
import FontIcon from '../../components/FontIcon';
import Link from '../../components/Link';
import theme, { colors } from '../../styles/theme';

const EmployeeName = styled.div`
  font-size: ${theme.fontSizes.l};
  font-weight: 900;
  color: ${colors.hmBrandPurple};
  font-family: ${theme.fonts.lato};
  text-transform: uppercase;
`;

const EmployeePosition = styled.div`
  font-size: ${theme.fontSizes.l};
  color: ${colors.hmGrey3};
  white-space: nowrap;

  @media only screen and (max-width: 899px) {
    white-space: normal;
  }
`;

const SocialIcon = styled(FontIcon)`
  color: #007bb6;

  &:hover {
    color: ${darken(0.1, '#007bb6')};
  }

  cursor: pointer;
`;

const PhotoImage = styled(Img)`
  /*border-radius: 3px;*/
`;

const EmployeeBox = ({ name, linkedIn, photo, position }) => (
  <Wrapper width="120px">
    <PhotoImage fluid={photo} />
    <FlexWrapper m="15px 0px 5px 0px">
      <EmployeeName>{name}</EmployeeName>

      {linkedIn && (
        <Link href={linkedIn} mt="-2px">
          <SocialIcon name="linkedin2" fontSize="16px" ml="5px" />
        </Link>
      )}
    </FlexWrapper>
    <EmployeePosition>{position}</EmployeePosition>
  </Wrapper>
);

EmployeeBox.propTypes = {
  name: PropTypes.string.isRequired,
  linkedIn: PropTypes.string.isRequired,
  photo: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  position: PropTypes.string.isRequired,
};

export default EmployeeBox;
