import React from 'react';
import Section from '../../components/Section';
import Heading from '../../components/Heading';
import { PageWrapper } from '../../components/Wrapper';
import Grid from '../../components/Grid';
import Paragraph from '../../components/Paragraph';
import LifeAtMentorsGridItem from './LifeAtMentorsGridItem';
import theme from '../../styles/theme';

import outTeamIcon from '../../assets/images/jobs/our-team.svg';
import workStationIcon from '../../assets/images/jobs/work-station.svg';
import mealsSnacksIcon from '../../assets/images/jobs/meals-snacks.svg';
import vacationIcon from '../../assets/images/jobs/vacation.svg';

const LifeAtMentorsSection = () => (
  <Section>
    <PageWrapper>
      <Heading textAlign="center" mb="10px">
        Life at
        <strong> 100mentors</strong>
      </Heading>
      <Paragraph fontWeight={theme.fontWeights.light} textAlign="center" maxWidth="730px" margin="0 auto 30px auto">
        You will join a team where active listening and intellectual humility are at the forefront, along with taking
        ownership and making data-driven decisions.
      </Paragraph>
      <Grid
        gridTemplateColumns="1fr 1fr"
        gridTemplateAreas={[
          "'ourTeam ourTeam' 'workStation workStation' 'mealsAndSnacks mealsAndSnacks' 'flexVacation flexVacation'",
          "'ourTeam workStation' 'mealsAndSnacks flexVacation'",
        ]}
        gridRowGap="50px"
      >
        <LifeAtMentorsGridItem gridArea="ourTeam" title="Our team" iconSrc={outTeamIcon}>
          The team is the heart of a company. We love spending time together in and out of the office. Whether through
          lunchtime storytelling, birthday surprises, after-work drinks, or a quick game of office basketball, our team
          is all about having a great time with the people you work with everyday.
        </LifeAtMentorsGridItem>

        <LifeAtMentorsGridItem gridArea="workStation" title="Build your work station" iconSrc={workStationIcon}>
          We believe people do the best work when they’re in their zone. Make your workspace uniquely you with
          personalized layout, decorations, snacks, supplies, and anything else you need to get your creative juices
          flowing.
        </LifeAtMentorsGridItem>

        <LifeAtMentorsGridItem gridArea="mealsAndSnacks" title="Meals & Snacks" iconSrc={mealsSnacksIcon}>
          Whether it’s with fresh fruit from the local market, or with chocolate-berry cereal bars, our kitchen is
          always stocked with great snacks, and a lot of coffee! Forgot lunch? See what’s cooking at local restaurants,
          and get in on the group order.
        </LifeAtMentorsGridItem>

        <LifeAtMentorsGridItem gridArea="flexVacation" title="Flexible Vacation" iconSrc={vacationIcon}>
          Found an unbeatable flight to Morocco? Invited on a ski trip in Austria? Our flexible vacation policy works
          with your lifestyle, not against it. Take the breaks you need. We trust you.
        </LifeAtMentorsGridItem>
      </Grid>
    </PageWrapper>
  </Section>
);

export default LifeAtMentorsSection;
