import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { FluidImage } from 'src/components/Image';
import playImage from 'src/assets/images/showcase/playImage.svg';
import qaaImage from 'src/assets/images/showcase/qaa-type.svg';
import spotlightImage from 'src/assets/images/showcase/spotlight-type.svg';
import S from './template.MentorsSpotlight.Styled';

const SpotlightCard = ({ photo, link, type, name }) => {
  return (
    <S.Spotlight>
      <S.SpotlightLink href={link} target="_blank" rel="noopener noreferrer">
        <S.PhotoContainer>
          <FluidImage image={photo} alt="" />
          <S.SpotlightType>
            {type === 'Q&A' && <img alt="spotlight type" src={qaaImage} />}
            {type === 'SPOTLIGHT' && <img alt="spotlight type" src={spotlightImage} />}
          </S.SpotlightType>
          {type === 'Q&A' && <S.PlayImage src={playImage} />}
        </S.PhotoContainer>
      </S.SpotlightLink>
      <S.SpotlightFooter>
        <S.SpotlightMentorName>{name}</S.SpotlightMentorName>
      </S.SpotlightFooter>
    </S.Spotlight>
  );
};

SpotlightCard.propTypes = {
  photo: PropTypes.shape({ childImageSharp: PropTypes.shape({ fluid: Img.propTypes.fluid }) }).isRequired,
  link: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const MentorsSpotlightTemplate = ({ bgColor, heading, description, title, spotlights }) => {
  return (
    <S.MentorsSpotlightSection bgColor={bgColor}>
      <S.PageWrapper>
        <S.Heading>{heading}</S.Heading>
        <S.Description>{description}</S.Description>
        {title && <S.Title>{title}</S.Title>}
        <S.Mentors>
          {spotlights.map(spotlight => (
            <SpotlightCard
              key={spotlight.name}
              photo={spotlight.photo}
              link={spotlight.link}
              type={spotlight.type}
              name={spotlight.name}
            />
          ))}
        </S.Mentors>
      </S.PageWrapper>
    </S.MentorsSpotlightSection>
  );
};

MentorsSpotlightTemplate.propTypes = {
  bgColor: PropTypes.string,
  heading: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  title: PropTypes.string,
  spotlights: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.shape({ childImageSharp: PropTypes.shape({ fluid: Img.propTypes.fluid }) }).isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

MentorsSpotlightTemplate.defaultProps = {
  bgColor: 'white',
  title: undefined,
};
