import styled from 'styled-components';
import media from 'src/styles/theme/media';
import Wrapper from './Wrapper';

const PageWrapper = styled(Wrapper)`
  box-sizing: border-box;
  min-width: 320px;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 20px;

  ${media.sm`
    padding: 80px 20px;
  `};
`;

export default PageWrapper;
