import styled from 'styled-components';
import Header from 'src/components/Section';
import { InnerLink } from 'src/components/Link';
import { PageWrapper } from 'src/components/Wrapper';
import Heading from 'src/components/Heading';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import { colors, font, media } from 'src/styles/theme';

export default {
  Header: styled(Header)`
    background-color: #fff6ea;
  `,
  PageWrapper: styled(PageWrapper)`
    display: flex;
    justify-content: center;
    width: 100%;
  `,
  InnerLink: styled(InnerLink)`
    position: absolute;
    width: 120px;
    top: 40px;
    left: 20px;

    ${media.sm`
      width: 170px;
      top: 30px;
      left: 30px;
    `}
  `,
  Content: styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    margin-top: 40px;

    ${media.md`
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      gap: 100px;
      margin: 90px 0px;
    `}
  `,
  OrangeText: styled.span`
    color: ${colors.hmOrange};
  `,
  TextContainer: styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 460px;

    ${media.md`
      align-items: start;
    `}
  `,
  PreHeading: styled(Heading)`
    color: ${colors.hmBlack};
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
  `,
  Heading: styled(Heading)`
    font-family: ${font.roboto};
    font-size: 38px;
    font-weight: 700;
    margin-top: 10px;
    text-align: center;

    ${media.md`
      text-align: left;
    `}
  `,
  SubHeading: styled(Heading)`
    font-family: ${font.roboto};
    font-size: 22px;
    font-weight: ${font.fontWeights.light};
    margin-top: 20px;
    text-align: center;

    ${media.md`
      text-align: left;
    `}
  `,
  CtaContainer: styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    gap: 15px;
    width: 100%;
    margin-top: 30px;

    ${media.md`
      flex-flow: row nowrap;
    `}
  `,
  Input: styled(Input)`
    flex: 1;
    padding: 15px 20px;
    border-color: ${colors.white};
    border-radius: 4px;

    :focus {
      border-color: ${colors.hmOrange};
    }
  `,
  Button: styled(Button)`
    padding: 15px 20px;
    border-radius: 4px;
  `,
  ImageWrapper: styled.div`
    width: 100%;
    max-width: 570px;
    margin-top: 60px;

    ${media.md`
      margin-top: none;
    `}
  `,
  SuccessMessage: styled.div`
    font-weight: 300;
    color: ${colors.hmGreen};
    margin-top: 20px;
  `,
  FailureMessage: styled.div`
    font-weight: 300;
    color: ${colors.hmRed};
    margin-top: 20px;
  `,
};
