import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';
import LeftQuoteImage from 'src/assets/images/LeftQuote.png';
import RightQuoteImage from 'src/assets/images/RightQuote.png';
import Heading from 'src/page-components/shared/Heading';
import bgImage from 'src/assets/images/home/testimonials-bg.png';
import stories from './StoriesSection.stories';
import S from './StoriesSection.Styled';

const TestimonialCard = ({ companySrc, companyImgWidth, text, employeeName, employeePosition, employeeCompany }) => (
  <S.TestimonialCard>
    <S.CompanyLogo fluid={companySrc} width={companyImgWidth} />
    <S.TestimonialContainer>
      <S.Quote src={LeftQuoteImage} />
      <S.Testimonial>{text}</S.Testimonial>
      <S.Quote src={RightQuoteImage} />
    </S.TestimonialContainer>
    <S.EmployeeName>{employeeName}</S.EmployeeName>
    <S.EmployeePosition>
      {employeePosition} <br /> at {employeeCompany}
    </S.EmployeePosition>
  </S.TestimonialCard>
);
TestimonialCard.propTypes = {
  companySrc: PropTypes.any.isRequired,
  companyImgWidth: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  employeeName: PropTypes.string.isRequired,
  employeePosition: PropTypes.string.isRequired,
  employeeCompany: PropTypes.string.isRequired,
};

const settings = {
  infinite: true,
  autoplay: false,
  dots: false,
  draggable: true,
  arrows: false,
  easing: 'ease-in-out',
};

const TestimonialSlider = React.createRef();

export const StoriesSection = () => {
  const data = useStaticQuery(graphql`
    query {
      ey: file(relativePath: { eq: "companies/stories/EY_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 85) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      pwc: file(relativePath: { eq: "companies/stories/pwc.png" }) {
        childImageSharp {
          fluid(maxWidth: 113) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      hellenicPetroleum: file(relativePath: { eq: "companies/stories/Hellenic_Petroleum.png" }) {
        childImageSharp {
          fluid(maxWidth: 137) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      intrasoft: file(relativePath: { eq: "companies/stories/intrasoft.png" }) {
        childImageSharp {
          fluid(maxWidth: 189) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  const testimonials = stories(data);

  return (
    <S.StoriesSection>
      <S.PageWrapper>
        <S.Background>
          <img src={bgImage} style={{ width: '100%', height: '100%' }} alt="" />
        </S.Background>
        <S.Container>
          <Heading>User Stories</Heading>
          <S.CarouselWrapper>
            <S.ButtonsWrapper>
              <S.CircleButtonLeft iconName="arrow-right-bold" onClick={() => TestimonialSlider.current.slickPrev()} />
              <S.CircleButtonRight iconName="arrow-right-bold" onClick={() => TestimonialSlider.current.slickNext()} />
            </S.ButtonsWrapper>
            <Slider ref={TestimonialSlider} {...settings}>
              {testimonials.map(testimonial => (
                <S.TestimonialCardWrapper key={testimonial.name}>
                  <TestimonialCard
                    companyName={testimonial.companyName}
                    companySrc={testimonial.companyLogo}
                    companyImgWidth={testimonial.companyLogoWidth}
                    text={testimonial.text}
                    employeeName={testimonial.employeeName}
                    employeePosition={testimonial.employeePosition}
                    employeeCompany={testimonial.employeeCompany}
                  />
                </S.TestimonialCardWrapper>
              ))}
            </Slider>
          </S.CarouselWrapper>
        </S.Container>
      </S.PageWrapper>
    </S.StoriesSection>
  );
};
