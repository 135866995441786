import React from 'react';
import Heading from 'src/components/Heading';
import Styled from './CookiesContent.Styled';

const CookiesContent = () => (
  <Styled.CookiesContent>
    <Heading mb="25px">Cookie Policy</Heading>
    <p>
      100mentors (&ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo;) are committed to protecting and respecting your
      privacy as described in our <Styled.InnerLink to="/privacy">Privacy Policy</Styled.InnerLink> of which this Cookie
      Policy forms a part.
    </p>
    <p>
      By consenting to this cookie policy (&ldquo;Cookie Policy&rdquo;), you acknowledge and consent to our website
      using cookies and other similar technologies (as described below) for the purpose described below. By continuing
      to use our site, you agree to Cookie Policy and eventually any additional cookies we may use, being placed on your
      device.&nbsp;
    </p>
    <Heading size={3} bold>
      What are cookies?
    </Heading>
    <p>
      A cookie is a small text file that is stored by a website on your computer, phone or other devices with
      information about your browsing behaviour on this website. Cookies are necessary to make navigation easier and
      more user-friendly. They do not cause any damage to your computer. Although the general term "cookie" is used in
      this data protection policy, as this is the main storage method used for information on this website, "local
      storage" of temporary internet files in the browser will be used for the same purposes as cookies. Accordingly,
      all information contained in this section also applies to "local storage".
    </p>
    <p>
      There are two different types of cookies: Session and Persistent. They are used for different things and contain
      different information.
    </p>
    <p>
      <strong>Session cookies</strong>&nbsp;exist only during one session&nbsp;and contain information that is used
      within your current browser session. These cookies are automatically deleted when you close your browser&nbsp;or
      turn off your computer. Nothing is persisted on your machine beyond the time you spend on the site.&nbsp;
    </p>
    <p>
      <strong>Persistent cookies</strong>&nbsp;remain on your computer or mobile device even after you close your
      browser or turn off your computer. Persistent cookies are used to hold information that is used between visits and
      make it possible to recognize you during subsequent visits to the website or application&nbsp;and react
      accordingly. Persistent cookies remain on your device until their end date is reached, until a new cookie is
      installed or until you delete them in the settings of your browser, application or mobile device.
    </p>
    <Heading size={3} bold>
      How does 100mentors use cookies?&nbsp;
    </Heading>
    <p>We use cookies for the following purposes:</p>
    <p>&nbsp;</p>
    <Styled.Table>
      <tbody>
        <tr>
          <Styled.HeadCell>
            <p>Authentication, Security and Integrity</p>
          </Styled.HeadCell>
          <td>
            <p>To log you into 100mentors and prevent unauthorized parties from accessing your accounts.</p>
            <p>To protect your security</p>
            <p>
              To help detect and fight spam, abuse, phishing attacks and other activities that violate 100mentors{' '}
              <Styled.InnerLink to="/terms">Terms &amp; Conditions</Styled.InnerLink> and{' '}
              <Styled.InnerLink to="/privacy">Privacy Policy</Styled.InnerLink>
            </p>
            <p>To prevent fraudulent use of login credentials, protect user data from unauthorized parties</p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>Site features, services, and user preferences</p>
          </Styled.HeadCell>
          <td>
            <p>
              To provide functionality that help us deliver products and services and change the way the Website or
              Services behaves or looks. For example, knowing when you&rsquo;ve already taken a product tour so we
              don&rsquo;t show it to you again and storing preferences to know when you&rsquo;ve seen or interacted with
              certain content, and provide you with customized content and experiences.
            </p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>Localization</p>
          </Styled.HeadCell>
          <td>
            <p>
              To help us provide localized experiences. For example, we may store information in a cookie that is placed
              on your browser or device so you will see the site in your preferred language.
            </p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>Preferences</p>
          </Styled.HeadCell>
          <td>
            <p>To remember data about your browser and your preferences</p>
            <p>To remember your settings and other choices you&rsquo;ve made</p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>Personalized content</p>
          </Styled.HeadCell>
          <td>
            <p>To customize 100mentors with more relevant content</p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>Session state</p>
          </Styled.HeadCell>
          <td>
            <p>
              To collect information about how Users interact with a website. This may include the pages users visit
              most often and whether users get error messages from certain pages.
            </p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>Performance</p>
          </Styled.HeadCell>
          <td>
            <p>
              To help us ensure 100mentors is operationally performing at a high level, such as by understanding how
              quickly our Services loads for different users.
            </p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>Analytics and research</p>
          </Styled.HeadCell>
          <td>
            <p>
              To help us improve and understand how Users ( Teachers, Educators, Mentors) use 100mentors and thus so we
              can continue to find ways to make 100mentors a great experience. For example, we may use these
              technologies to know which features on 100mentors are most popular or which content Users prefer
            </p>
          </td>
        </tr>
      </tbody>
    </Styled.Table>
    <Heading size={3} bold>
      Who uses the information stored in the cookies?
    </Heading>
    <p>
      The information stored in the cookies on our website is used exclusively by us, except for the information
      identified as "third party cookies" below. These are used and managed by external organisations to provide us with
      services that are requested by us in order to improve our services and the customer experience of our website. The
      most important services for which these "third party cookies" are used are the preparation of access statistics.
    </p>
    <Heading size={3} bold>
      How can I disable or prevent the use of cookies on this website?
    </Heading>
    <p>
      We will ask for your consent to the use of cookies on your first visit to the Services. We may derive your consent
      from the note in a box and the "I agree" option (or a corresponding key) or from the fact that you continue to
      browse our Services, despite the fact that a clear and visible warning is displayed on our Services that we use
      cookies. If you agree, we will place a consent cookie on your device. If, in view of the above, you wish to avoid
      the use of cookies on this website you must first deactivate the use of cookies in your browser and then delete
      the cookies stored in your browser that are associated with the website. This option to avoid using cookies can be
      used at any time. However, you should be aware that disabling cookies from your browser may not allow you to take
      advantage of all the features of the Site and some of the features of the Site and Services may not work as they
      should as they rely on cookies.
    </p>
    <Heading size={3} bold>
      How can I deactivate and delete cookies?
    </Heading>
    <p>
      You can restrict, block or delete cookies on this website at any time by changing your browser configuration using
      the following instructions. All web browsers allow you to limit cookie behaviour or turn off cookies within the
      settings or options of the browser. The steps to do this are different for each browser, you can find instructions
      under the Help menu of your browser or by visiting www.allaboutcookies.org. Please note, however, that without
      cookies you may not be able to take full advantage of all our websites features.&nbsp;
    </p>
    <p>Instruction for deactivation and deletion in browsers, you can find in the below links:</p>
    <ul>
      <li>
        Internet Explorer&nbsp;
        <a href="https://support.microsoft.com/kb/278835" target="_blank" rel="noopener noreferrer">
          https://support.microsoft.com/kb/278835
        </a>
      </li>
      <li>
        Firefox&nbsp;
        <a
          href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
        </a>
      </li>
      <li>
        Chrome&nbsp;
        <a href="http://support.google.com/accounts/answer/61416?hl=en" target="_blank" rel="noopener noreferrer">
          https://support.google.com/accounts/answer/61416?hl=en
        </a>
      </li>
      <li>
        Safari&nbsp;
        <a href="http://support.apple.com/kb/PH5042" target="_blank" rel="noopener noreferrer">
          https://support.apple.com/kb/PH5042
        </a>
      </li>
      <li>
        Safari for iPad and iPhone&nbsp;
        <a href="https://support.apple.com/kb/HT1677" target="_blank" rel="noopener noreferrer">
          https://support.apple.com/kb/HT1677
        </a>
      </li>
    </ul>
    <Heading size={3} bold>
      Which cookies does the website use exactly?
    </Heading>
    <p>
      The following list details the cookies, tags or similar applications used on this website, together with
      information about their purpose (by us or by third parties).
    </p>
    <p>
      <u>
        <strong>By us</strong>, we use{' '}
      </u>
      "local storage" of temporary internet files in the browser for the same purposes as cookies.
    </p>
    <p>
      <u>
        In addition, the following are the third party cookies in use, where you have accepted their use (which are each
        subject to their own privacy and cookie policies):
      </u>
    </p>
    <Styled.Table>
      <tbody>
        <tr>
          <Styled.HeadCell>
            <p>Cookie Name</p>
          </Styled.HeadCell>
          <td>Function</td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>Google Analytics</p>
          </Styled.HeadCell>
          <td>
            <p>
              The&nbsp;web analytics service offered by Google, Inc. (&ldquo;Google&rdquo;), to analyse how our Website
              is used. This service uses cookies generating information on your use of our Website (including your IP
              address), collected from Google in an anonymous form (using non-personally identifiable data). Privacy
              policy available at&nbsp;
              <a href="https://www.google.com/intl/en/analytics/privacyoverview.html">
                https://www.google.com/intl/en/analytics/privacyoverview.html
              </a>
              &nbsp;
            </p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>Facebook Cookies</p>
          </Styled.HeadCell>
          <td>
            <p>
              These allow you to log in and use the Site and our Services through Facebook, saving you from having to
              sign up and helping us identify you as a User through your social media accounts.
              <Styled.InnerLink to="/privacy">Privacy Policy</Styled.InnerLink> available&nbsp;
              <a href="https://www.facebook.com/full_data_use_policy">https://www.facebook.com/full_data_use_policy</a>
            </p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>LinkedIn</p>
          </Styled.HeadCell>
          <td>
            <p>
              These allow you to log in and use the Site and our Services through LinkedIn, saving you from having to
              sign up and helping us identify you as a User through your social media accounts.{' '}
              <a href="https://www.linkedin.com/legal/privacy-policy?trk=homepage-basic_footer-privacy-policy">
                https://www.linkedin.com/legal/privacy-policy?trk=homepage-basic_footer-privacy-policy
              </a>
            </p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>Hotjar</p>
          </Styled.HeadCell>
          <td>
            <p>
              Hotjar uses cookies to process information including standard internet log information and details of the
              visitor&rsquo;s behavioral patterns upon visiting our site. This is done to provide our users with a
              better experience, and to facilitate the use of certain functions.{' '}
              <a href="https://www.hotjar.com/legal/policies/privacy">https://www.hotjar.com/legal/policies/privacy</a>
            </p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>Auth0</p>
          </Styled.HeadCell>
          <td>
            <p>
              Used by Auth0 to identify user log-in and authentication information.{' '}
              <a href="https://auth0.com/privacy">https://auth0.com/privacy</a>
            </p>
          </td>
        </tr>
        <tr>
          <Styled.HeadCell>
            <p>YouTube</p>
          </Styled.HeadCell>
          <td>
            <p>Used by YouTube to track the actions of our Users on YouTube website when they use our Services.</p>
          </td>
        </tr>
      </tbody>
    </Styled.Table>
    <Heading size={3} bold>
      Cookies from unregistered users
    </Heading>
    <p>
      We still use cookies if you don&rsquo;t have an account or have logged out of your account. For example, if you
      have logged out of your account we use cookies to, for example, help you log in faster the next time.
    </p>
    <p>
      We also set cookies if you don&rsquo;t have a 100mentors account, but have visited 100mentors.com, to help us
      protect our Services and the people who use it from malicious activity. For example, these cookies help us detect
      and prevent denial-of-service attacks and the mass creation of fake accounts. If you have cookies on your browser
      or device, we read that cookie when you visit a site with our app.
    </p>
    <Heading size={3} bold>
      Updates &amp; Contact Info
    </Heading>
    <p>
      This privacy notice was last updated on <strong>2 October 2019</strong>
    </p>
    <p>
      &nbsp;From time to time, we may update this Cookie Policy. If we do, we&rsquo;ll notify you by posting the policy
      on our site with a new effective date. If we make any material changes, we&rsquo;ll take reasonable steps to
      notify you in advance of the planned change.
    </p>
    <p>
      If you have any questions about our use of cookies, please email us at&nbsp;
      <a href="mailto:info@100mentors.com">info@100mentors.com</a>&nbsp;
    </p>
  </Styled.CookiesContent>
);

export default CookiesContent;
