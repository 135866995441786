import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { FixedImage } from 'src/components/Image';
import mentorsLogo from 'src/assets/images/100mentors-logo-white.svg';
import brownLogo from 'src/assets/images/showcase/brown/brown-logo.png';

import S from './brown.Hero.Styled';

export const HeroBrown = ({ schools, students, mentors }) => {
  const { bottomImage } = useStaticQuery(graphql`
    query {
      bottomImage: file(relativePath: { eq: "showcase/brown/stem-logo.png" }) {
        childImageSharp {
          fixed(width: 380) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <S.HeadSection>
      <S.PageWrapper>
        <S.Logo src={mentorsLogo} />
        <S.Content>
          <S.TextContainer>
            <S.LogoContainer>
              community stem @ <S.BrownLogo src={brownLogo} alt="Brown logo" /> X
              <S.MentorsLogo src={mentorsLogo} />
            </S.LogoContainer>
            <S.Heading>Bridging communities to impact STEM access and learning anywhere, anytime</S.Heading>
            <S.Description>
              We are an independent group of scientists from Brown University aiming to inspire and mentor middle school
              students from under-represented groups in STEM. <S.BreakLine /> Via a collaboration with 100mentors, we
              are using technology as a means to <S.BreakLine /> by-pass historical and structural barriers to STEM.
            </S.Description>
            <S.StatsContainer>
              <S.StatItem>
                <S.Stat>{schools}</S.Stat>
                <S.Metric>schools</S.Metric>
              </S.StatItem>
              <S.StatItem>
                <S.Stat>{students}</S.Stat>
                <S.Metric>students</S.Metric>
              </S.StatItem>
              <S.StatItem>
                <S.Stat>{mentors}</S.Stat>
                <S.Metric>mentors</S.Metric>
              </S.StatItem>
            </S.StatsContainer>
          </S.TextContainer>
          <S.ImageContainer>
            <FixedImage image={bottomImage} alt="Bottom image" style={{ display: 'block', marginTop: '70px' }} />
          </S.ImageContainer>
        </S.Content>
      </S.PageWrapper>
    </S.HeadSection>
  );
};

HeroBrown.propTypes = {
  schools: PropTypes.number.isRequired,
  students: PropTypes.number.isRequired,
  mentors: PropTypes.number.isRequired,
};
