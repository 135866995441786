import styled from 'styled-components';
import { color, space } from 'styled-system';
import { darken } from 'polished';
import { colors } from '../../styles/theme';

const Link = styled.a`
  text-decoration: none;

  &:visited {
    ${color};
  }

  &:hover {
    color: ${props => darken(0.2, colors[props.color])};
  }

  ${color};
  ${space};
`;

Link.propTypes = {
  ...color.propTypes,
  ...space.propTypes,
};

Link.defaultProps = {
  color: 'hmBlack',
};

export default Link;
