const hmColors = {
  hmBrandPurple: '#794c91',
  hmBrandPurple10: 'rgba(121, 76, 145, 0.1)',
  hmBrandPurple50: 'rgba(121, 76, 145, 0.5)',
  hmBrandYellow: '#fdc010',
  hmBrandYellow10: 'rgba(253, 192, 16, 0.1)',
  hmBlack: '#3e3e3e',
  hmGrey1: '#e8e8e8',
  hmGrey2: '#c3c3c3',
  hmGrey3: '#898989',
  hmRed: '#e53d3d',
  hmGreen: '#60cb92',
  hmGreen10: 'rgba(96, 203, 146, 0.1)',
  hmBlue: '#068deb',
  hmBlue10: 'rgba(6, 141, 235, 0.1)',
  hmPink: '#dd6d8f',
  hmPurple2: '#cac1d3',
  hmOrange: '#f69114',
};

const colors = {
  ...hmColors,
  white: '#fff',
  heather: '#a59ab0',
  paleLavender: '#fbf8ff',
  paleGrey: '#fcfbfd',
  paleRed: '#db5847',

  gradients: {
    BRAND: 'linear-gradient(225deg, #8756ea, #d04c90 50%, #fdc010)',
    ORANGE: 'linear-gradient(315deg, #f7bf3d, #c65415)',
    RED: 'linear-gradient(315deg, #fa813d, #b1272a)',
    PURPLE: 'linear-gradient(315deg, #ac2fde, #5235c9)',
    BLUE: 'linear-gradient(315deg, #7e5efe, #3299c9)',
    CYAN: 'linear-gradient(315deg, #5ccdff, #3da990)',
    GREEN: 'linear-gradient(315deg, #6adcc1, #5aa617)',
  },
};

export default colors;
