import styled, { css } from 'styled-components';
import { colors, font } from 'src/styles/theme';

export default {
  StatCard: styled.div`
    background-color: #ffffff;
    border: solid 1px ${colors.hmGrey1};
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    min-width: 200px;
    max-width: ${({ maxWidth }) => maxWidth || '360px'};
    width: 100%;
    ${({ span }) =>
      span &&
      css`
        grid-column: span ${span};
      `}
  `,
  ImageContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ bgColor }) =>
      css`
        background: ${bgColor};
      `}
    border-radius: 8px;
    margin: 9px 20px 9px 9px;
    width: 102px;
    height: 102px;
  `,
  Image: styled.img`
    height: 50px;
  `,
  Footer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Stat: styled.div`
    font-size: 38px;
    font-family: ${font.fonts.lato};
    font-weight: ${font.fontWeights.bold};
    color: ${({ statColor }) => statColor};
    margin-bottom: 5px;
  `,
  Text: styled.div`
    font-size: 17px;
    font-weight: ${font.fontWeights.bold};
    font-family: ${font.fonts.lato};
    color: #000000;
    max-width: 190px;
  `,
};
