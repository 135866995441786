import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'src/styles/theme';
import S from './StatCard.Styled';

export const StatCard = ({ className, bgImageColor, image, stat, statColor, text, maxWidth }) => (
  <S.StatCard className={className} maxWidth={maxWidth}>
    <S.ImageContainer bgColor={bgImageColor}>
      <S.Image src={image} />
    </S.ImageContainer>
    <S.Footer>
      <S.Stat statColor={statColor}>{stat}</S.Stat>
      <S.Text>{text}</S.Text>
    </S.Footer>
  </S.StatCard>
);

StatCard.propTypes = {
  className: PropTypes.string,
  bgImageColor: PropTypes.string,
  image: PropTypes.string.isRequired,
  stat: PropTypes.number.isRequired,
  statColor: PropTypes.string,
  text: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
};

StatCard.defaultProps = {
  className: '',
  bgImageColor: colors.hmGrey1,
  statColor: colors.hmBlack,
  maxWidth: undefined,
};
