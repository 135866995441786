import styled from 'styled-components';
import Section from 'src/components/Section';
import Heading from 'src/components/Heading';
import { colors, media, font } from 'src/styles/theme';

export default {
  ExperienceSection: styled(Section)``,
  Content: styled.div`
    text-align: center;
  `,
  Heading: styled(Heading)`
    padding-bottom: 10px;
  `,
  Text: styled.div`
    font-weight: ${font.fontWeights.light};
    margin-bottom: 60px;
  `,
  StepContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 900px;
    margin: auto;

    ${media.md`
      flex-direction: row;
    `};
  `,
  Step: styled.div`
    text-align: center;
    max-width: 250px;
    z-index: 1;

    :not(:last-child) {
      margin-bottom: 50px;

      ${media.md`
        margin-bottom: 0;
      `};
    }

    :nth-child(2) > :first-child > :first-child {
      top: -5px;
    }
    :nth-child(3) > :first-child > :first-child {
      left: -35px;
    }
    :nth-child(4) > :first-child > :first-child {
      border-radius: 2px;
      filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.1));
    }
  `,
  StepCard: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 190px;
    border-radius: 12px;
    margin: 0 auto 15px auto;
    background-color: ${colors.paleGrey};
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.1);
    overflow: visible;
  `,
  Title: styled(Heading)`
    font-size: ${font.headingFontSizes.s};
    font-weight: ${font.fontWeights.bold};
    margin-bottom: 10px;
  `,
  Description: styled.div`
    font-size: ${font.fontSizes.l};
    font-weight: ${font.fontWeights.light};
  `,
  Line: styled.img`
    display: none;
    position: absolute;
    top: 60px;
    right: 5%;

    ${media.md`
      display: block;
    `};
  `,
};
