import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import PropTypes from 'prop-types';
import { darken } from 'polished';
import Ink from 'react-ink';
import theme, { colors } from '../../styles/theme';

const ButtonStyled = styled.button`
  position: relative;
  padding: 14px;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.m};
  text-transform: uppercase;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  transition: box-shadow 200ms ease;
  -webkit-tap-highlight-color: transparent;

  color: ${({ color }) => (color === 'white' ? colors.hmBrandPurple : 'white')};
  background-color: ${({ color }) => colors[color]};
  box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  &:focus {
    outline: none;
  }

  &:active {
    box-shadow: none;
  }

  &:hover {
    background-color: ${({ color }) => darken(color === 'white' ? 0.03 : 0.05, colors[color])};
  }

  ${space};
`;

const Button = ({ className, color, children, href, rel, target, ...restProps }) => (
  <>
    {href && (
      <a href={href} rel={rel} target={target} className={className}>
        <ButtonStyled type="button" color={color} {...restProps}>
          {children}
          <Ink style={{ color: color === 'white' ? colors.hmBrandPurple : colors.white }} opacity={0.15} />
        </ButtonStyled>
      </a>
    )}

    {!href && (
      <ButtonStyled className={className} type="button" color={color} {...restProps}>
        {children}
        <Ink style={{ color: color === 'white' ? colors.hmBrandPurple : colors.white }} opacity={0.15} />
      </ButtonStyled>
    )}
  </>
);

Button.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  ...space.propTypes,
};

Button.defaultProps = {
  color: 'white',
};

export default Button;
