import styled from 'styled-components';
import PropTypes from 'prop-types';
import { space, width } from 'styled-system';
import LogoColoredFile from '../../assets/images/100mentors-logo-colored.svg';
import LogoWhiteFile from '../../assets/images/100mentors-logo-white.svg';
import LogoSquareFile from '../../assets/images/100mentors-logo-square.svg';

const logoSrc = ({ type, colored }) => {
  if (type === 'square') return LogoSquareFile;
  if (type === 'wide') {
    if (colored) return LogoColoredFile;
    return LogoWhiteFile;
  }

  return '';
};

const Logo = styled.img.attrs(props => ({
  src: logoSrc(props),
}))`
  ${space};
  ${width};
`;

Logo.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  type: PropTypes.oneOf(['square', 'wide']),
  colored: PropTypes.bool.isRequired,
};

Logo.defaultProps = {
  width: '130px',
  type: 'wide',
};

export default Logo;
