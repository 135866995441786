import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, space, fontWeight, fontSize } from 'styled-system';
import { transparentize } from 'polished';
import Ink from 'react-ink';
import theme, { colors } from '../../styles/theme';

import FontIcon from '../FontIcon';

const IconButtonStyled = styled.button`
  position: relative;
  border: none;
  background: transparent;
  outline: none;
  padding: 14px;
  text-transform: uppercase;
  transition: box-shadow 200ms ease;
  cursor: pointer;
  font-weight: ${theme.fontWeights.bold};
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: ${props => transparentize(0.2, colors[props.color])};
  }

  &:focus {
    outline: none;
  }

  ${color};
  ${space};
  ${fontWeight};
  ${fontSize};
`;

const IconButton = ({ iconName, iconSize, className, children, href, ...restProps }) => (
  <>
    {href && (
      <a href={href}>
        <IconButtonStyled type="button" className={className} {...restProps}>
          {children}
          <Ink style={{ color: colors[restProps.color] }} opacity={0.15} />
          <FontIcon name={iconName} fontSize={iconSize} ml="5px" />
        </IconButtonStyled>
      </a>
    )}
    {!href && (
      <IconButtonStyled type="button" className={className} {...restProps}>
        {children}
        <Ink style={{ color: colors[restProps.color] }} opacity={0.15} />
        <FontIcon name={iconName} fontSize={iconSize} ml="5px" />
      </IconButtonStyled>
    )}
  </>
);

IconButton.propTypes = {
  ...color.propTypes,
  ...space.propTypes,
  ...fontWeight.propTypes,
  ...fontSize.propTypes,
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  href: PropTypes.string,
};

IconButton.defaultProps = {
  // eslint-disable-next-line
  color: 'white',
  iconSize: '14px',
  href: null,
  // eslint-disable-next-line
  fontSize: theme.fontSizes.m,
};

export default IconButton;
