import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from 'src/components/Layout';
import Nav from 'src/components/Nav';
import MarkdownTemplate from 'src/templates/MarkdownTemplate';
import MainHelmet from '../components/MainHelmet';
import { colors } from '../styles/theme';
import Section from '../components/Section';
import { PageWrapper } from '../components/Wrapper';

const Terms = () => {
  const data = useStaticQuery(graphql`
    query {
      terms: markdownRemark(frontmatter: { slug: { eq: "terms" } }) {
        html
        frontmatter {
          date(formatString: "D MMMM YYYY")
          slug
          title
        }
      }
    }
  `);
  return (
    <Layout hideFooter>
      <MainHelmet addonTitle="Terms &amp; Conditions" />
      <Section>
        <Nav background={colors.hmBrandPurple} />
        <PageWrapper p={['100px 20px !important', '120px 20px !important']}>
          <MarkdownTemplate data={data.terms} />
        </PageWrapper>
      </Section>
    </Layout>
  );
};

export default Terms;
