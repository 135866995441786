import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

export const FixedImage = ({ image, style, imgStyle, alt }) => (
  <Img fixed={image.childImageSharp.fixed} style={style} imgStyle={imgStyle} alt={alt} />
);

FixedImage.propTypes = {
  image: PropTypes.object.isRequired,
  style: PropTypes.object,
  imgStyle: PropTypes.object,
  alt: PropTypes.string.isRequired,
};

FixedImage.defaultProps = {
  style: {},
  imgStyle: {},
};
